/* ++++++++++++++++++++
+++ LAYOUT: FILTERS +++
++++++++++++++++++++ */

.filter {
	@extend %flex;
    align-items: flex-end;
    justify-content: flex-start;
    max-width: $two-thirds-column;    
    padding: $gap * 1.5 $gap;
    
    &.filter-wide {
        max-width: $page-width;
        
        .filter-block {
            &:nth-of-type(5) {
                &.filter-block-submit {
                    text-align: center;
                    margin-top: 0;
                    
                    @media (min-width: 700px) {
                        margin-top: $gap * 3;
                    }
                    
                    @media (min-width: 832px) {
                        margin-top: $gap * 1.5;
                    }
                    
                    @media (min-width: 992px) {
                        margin-top: 0;
                    }
                    
                    .filter-submit {
                        width: 100%;
                        
                        @media (min-width: 832px) {
                            width: 25%;                        
                        }
                        
                        @media (min-width: 992px) {
                            width: 100%;
                        }
                    }
                }
            }
            
            &:nth-of-type(6) {
                &.filter-block-submit {
                    text-align: center;
                    margin-top: 0;
                    
                    @media (min-width: 700px) {
                        margin-top: $gap * 3;
                    }
                    
                    @media (min-width: 832px) {
                        margin-top: $gap * 3;
                    }
                    
                    @media (min-width: 992px) {
                        margin-top: $gap * 1.5;
                    }
                    
                    @media (min-width: 1183px) {
                        margin-top: 0;
                    }
                    
                    .filter-submit {
                        width: 100%;
                        
                        @media (min-width: 832px) {
                            width: 100%;
                        }
                        
                        @media (min-width: 992px) {
                            width: 25%;
                        }
                        
                        @media (min-width: 1183px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    
    @include break(small) {
        padding: $gap * 2;
    }
    
    @include break(medium) {
	    padding: $gap $gap $gap * 1.5;
    }
    
    &-outer {        
        &.light {
            background-color: $lightest-blue;
        }
        
        &.dark {
            background-color: $primary-light;
        }
        
        &.primary {
            background-color: $primary;
        }
        
        &.secondary {
            background-color: $secondary;
        }
	    
	    &.small {
		    .filter {
			    max-width: $half-column;
		    }
	    }
        
        &.dark,
        &.primary {
            color: $white;
    
            input,
            textarea,
            select {
                border-color: transparent;
            }
            .button {
                @extend %button;
                background: $secondary;
                color: $primary;
                width: 100%;
            
                &:hover {
                    background: $secondary-light;
                }
            
                &:active {
                    background: $secondary-dark;
                }
            }
        }
        &.products {
            .filter-block {
                @include flexbox(100%);
                margin: $gap / 4 0;
                
                @include break(tablet) {
                    @include flexbox(48%);
                }
                
                @include break(large) {
                    @include flexbox(33.33%);
                }
            }
        }
    }

    &-block {
        flex: 0 100%;
        // padding: 0 $gap * 2.5 $gap * 1.5;
        padding: 0 0 $gap * 1.5;
        text-align: left;
        
        &:last-of-type {
            padding-bottom: 0;
        }
        
        @include break(tablet) {
	        flex: 1 12em;
	        padding: 0 $gap;
        }
        
        label {
	        // color: $white;
	        font-weight: $bold;
        }
        
        input.filter-field {
            @extend %font-body;
            background-color: $white;
            border: 0;
            color: $blue;
            line-height: 1;
            padding: $gap / 1.5 $gap * 1.5;
            
            &::placeholder {
                color: $blue;
                opacity: 0.6;
            }
        }
        
        select {
            @extend %font-body;
            background-image: url('/assets/images/arrow-down.svg');
			// background-position: 94% center;
            background-position: 90% center;
            color: $blue;
            padding: $gap / 1.5 $gap * 4.5 $gap / 1.5 $gap * 1.5;
        }

        .selection {
            .select2-selection--multiple {
                .select2-selection__choice {
                    background-color: $lime;
                    border: none;
                    border-radius: 30px;
                    padding: 3px 10px 3px 25px;

                    &__remove {
                        left: 3px;
                        top: 50%;
                        transform: translateY(-50%);

                        &:hover {
                            background-color: $lime;
                        }
                    }
                }
            }
        }
        
    }

    &-submit {
        @extend %button-dark;
	    // margin-top: $gap * 1.5;
        width: 100%;
        
        @include break(small) {
		    margin-top: 0;
        }
    }
}
