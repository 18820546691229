//+++++++++++++++++++++++++++
//+++ HELPERS: TYPOGRAPHY +++
//+++++++++++++++++++++++++++

//=======================================
// Helpers
//=======================================


// This is the highlight colour used with the cursor
@include selection($background: $secondary, $color: $white);

// Form placeholder color
@include placeholder {
    color: currentColor;
    opacity: 0.4;
}


//=======================================
// Defaults
//=======================================

%font-header {
    font: $normal 1rem/1.2 $font-header;
}

%font-body {
    font: $normal 1.2rem/1.5 $font-body;
}

%font-content {
    font: $normal 1.2rem/1.5 $font-content;
}

%font-meta {
    font: $normal 1rem/1.5 $font-meta;
}

%font-label {
    font: normal 1.05rem/1.5 $font-body;
    text-transform: capitalize;
    // letter-spacing: 0.1em;
}

//=======================================
// Settings
//=======================================

%h1 {
    @extend %font-header;
    font-size: 2.25rem;
    // font-size: calc(2rem + 1.4vw);

    @include break(large) {
        font-size: 3.75rem;
    }
}

%h2 {
    @extend %font-body;
    font-size: 2rem;
    // font-size: calc(1.6rem + 1vw);

    @include break(large) {
        font-size: 2.5rem;
    }
}

%h3 {
    @extend %font-body;
    font-size: 1.5rem;
    // font-size: calc(1.36rem + 0.7vw);

    @include break(large) {
        font-size: 1.9rem;
    }
}

%h4 {
    @extend %font-body;
    font-size: 1.25rem;
    // font-size: calc(1.32rem + 0.4vw);

    @include break(large) {
        font-size: 1.5rem;
    }
}

%h5 {
    @extend %font-body;
    font-size: 1.3rem;
    // font-size: calc(1.75rem + 0.2vw);

    @include break(large) {
        font-size: 1.35rem;
    }
}

%h6 {
    @extend %font-body;
    font-size: 1.125rem;
    // font-size: calc(1.105rem + 0.1vw);

    @include break(large) {
        font-size: 1.2rem;
    }
}
