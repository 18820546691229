/* +++++++++++++++++
+++ LAYOUT: FEED +++
+++++++++++++++++ */

// Feed is the basic listing of content such as news posts, more scannable and content heavy than cards

.feed {
	max-width: $two-thirds-column;
}

.section-header + .feed {
	@include break(medium) {
		margin-top: $gap * 1.25;
	}
}

.feat-items {
	.container {
		max-width: $two-thirds-column;
	}
	
	.item {
		background-color: $white !important;
		border-radius: $corner;
		padding-bottom: 0;
		margin-top: 0;
		
		+ .item {
			margin-top: $gap * 2;
			padding-top: 0;
		}
	}
	
	.card {
		// background: $primary-bright;
		// color: $white;
		// 
		// &-summary {
		// 	color: rgba($white,0.8) !important;
		// }
	}
}

.alternating-order {
	.item {
		&:nth-of-type(even) {
			@include break(medium) {
				margin-left: -2rem;
				
				.item-info {
					order: 0;
				}
				
				.item-image {
					order: 1;
					
					img {
						border-radius: 0 0 $corner $corner;
						
						@include break(tablet) {
							border-radius: $corner 0 0 $corner;						
						}
					}
					
					+ .item-info {				
						@include break(medium) {
							padding-right: $gap * 2;
							padding-left: $gap * 3;        
						}
					}
				}
			}
		}
		
		&:nth-of-type(odd) {
			@include break(medium) {
				margin-right: -2rem;
			}
		}
		
	}
}

// .alternating-color {
// 	.item {
// 		&:nth-of-type(even) {
// 			background: lighten($lightest-blue, 10%);
// 		}
// 	}
// }

.item {
	@extend %flex;
	border-radius: $corner;
	border: 1px solid $grey80;
	flex-direction: row-reverse;
	// padding-bottom: $gap * 2;
	
	&:hover {
		@extend %transition;
		box-shadow: $shadow;
		transform: translateY(-3px);
		
		.item-image {
			img {
			}
		}
		
		.item-header-title {
			text-decoration: underline;
		}
	}
	
	@include break(medium) {
		padding-bottom: 0;
	}
	
    & + & {
		margin-top: $gap * 3;
    }

    &-image {
	    @include flexbox(100%);
        border-radius: $corner;
        
        img {
			@extend %transition;
			@extend %object-fit;
			border-radius: $corner $corner 0 0;
			
			@include break(tablet) {
				border-radius: 0 $corner $corner 0;				
			}
        }
    }
    
    &-info {
		@include flexbox(100%);
	    margin: 0;
		padding: $gap * 1.25 $gap * 2 $gap * 2 $gap * 2;
		
		@include break(medium) {
			padding: $gap * 2;
		}
    }

    &-link {
        @extend %text-hover;
    }

    &-header {
        margin-top: $gap / 2;

        &-title {
			margin-bottom: $gap / 1.5;
			
            a {
                @extend %text-hover;
            }
        }

        &-date, &-author {
            display: inline-block;
            margin: $gap / 2 0;
        }
        &-author {
	        &:before {
		        content: '|';
		        display: inline-block;
		        margin: 0 $gap / 2;
	        }
        }
    }

    &-summary {
        margin-left: 0;
        max-width: $half-column;
    }

    @include break(tablet) {
        & + & {
            // margin-top: $gap * 2;
            // padding-top: $gap * 2;
        }

		&-info {
			@include flexbox(100%);
		}

        &-image {
	        @include flexbox(35%);
	        
	        + .item-info {
				@include flexbox(100%);
				
		        @include break(small) {
					@include flexbox(65%);	
					padding-right: $gap * 3;	        
		        }
	        }

            & + * {
                margin-top: 0;
            }
        }
    }
}
