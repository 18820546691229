/* +++++++++++++++++++++++
+++ LAYOUT: STATISTICS +++
+++++++++++++++++++++++ */

.statistic-outer {
	.section-header {
		text-align: left;
		
		@include break(tablet) {
			text-align: center;
		}
		@include break(medium) {
			text-align: left;
		}
		
		&-title {
			font-size: 4rem;
			text-align: left;
			
			@include break(tablet) {
				text-align: center;
			}
			
			@include break(medium) {
				font-size: 5.5rem;
				text-align: left;
			}
		}
		
		&-summary {
			@include break(medium) {
				margin-bottom: $gap * 2;
			}
		}
		
		.button {
			margin-left: 0;
			margin-top: $gap * 2;
			
			@include break(tablet) {
				margin-bottom: $gap * 2;
				margin-left: auto;
				margin-right: auto;
			}
			
			@include break(medium) {
				margin-bottom: 0;
				margin-left: auto;
				margin-right: 0;
			}
		}
		&-column {
			@include flexbox(100%);
			margin-left: 0;
			
			@include break(medium) {
				@include flexbox(60%);
			}
			&:nth-of-type(2) {
				@include break(medium) {
					@include flexbox(40%);
				}	
			}
		}
	}
}

.statistic {
	@extend %flex;
	justify-content: center;
	margin-top: $gap * 2;
	
	@include break(medium) {
		margin-top: 0;
		padding: 0 $gap * 2;
		justify-content: space-between;
	}
    
    @include break(large) {
		margin-right: 0;
		max-width: 75%;
	    padding: 0;
    }
    
    &-item {
    	@include flexbox(100%);
    	margin: 0 0 $gap * 2;
		padding: 0 $gap;
		position: relative;
		z-index: 0;
		
		&:before {
			background-color: $white;
			border-radius: 100%;
			content: '';
			height: 124px;
			position: absolute;
			width: 124px;
			z-index: -1;
			top: 0.5rem;
			left: 0;
			
			@include break(tablet) {
				height: 144px;
				left: 25%;	
				width: 144px;
				left: 50%;
				top: 35%;
				transform: translate(-50%, -50%);
			}
			
			@include break(medium) {
				left: -2rem;
				transform: translate(0,0);
				top: auto;
				margin: 0;
			}
		}
    	
    	@include break(tablet) {
	    	@include flexbox(60%);
			text-align: center;
    	}
    	
    	@include break(medium) {
	    	@include flexbox(20%);
			margin: 0;
			padding: 0;
			text-align: left;
	    	
		    &:first-child:nth-last-child(2), &:first-child:nth-last-child(2) ~ .statistic-item {
	            @include flexbox(48%);
		    }
		    &:first-child:nth-last-child(3), &:first-child:nth-last-child(3) ~ .statistic-item {
	            @include flexbox(30%);
		    }
    	}
    	&:last-child {
	    	margin-bottom: $gap;
	    	
	    	@include break(small) {
	    		margin: 0 0 $gap * 2;
	    	}
    	}
    }

    &-icon {
        font-size: 2em;
    }

    &-value {
	    display: inline-block;
		line-height: 1;
		padding: 0;
	    
        &-number {
			font-family: $font-header;
			font-size: 4rem;
			// font-weight: $bold;
			
			@include break(medium) {
				font-size: 5.5rem;
			}
        }
    }
    &-title {
		font-size: 1.25rem;
		font-weight: $bold;
		padding: $gap / 2 0 $gap * 1.25;
    }
}
