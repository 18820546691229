/* ++++++++++++++++++++
+++ LAYOUT: CONTENT +++
++++++++++++++++++++ */

// Content is the section of the page that contains body copy, typically from a WYSIWYG area. This gets all the post styling such as blockquotes and lists.

.content {
    @extend %font-content;
    
    * {
		margin-top: $gap * 1.75;
		
        &:first-child {
	        margin-top: $gap * 1.75;
        }
    }
    
    h1 + p,
    h2 + p,
    h3 + p,
    h4 + p {
        margin-top: $gap;
    }
    
    h2,h4,h5,h6 {
        font-weight: $bold;
    }
    
    h3 {
        font-weight: $extrabold;
    }

    // Lists
    ol, ul {
	    margin-top: $gap;
        padding-left: $gap * 1.2;
    }

    li + li {
        margin: $gap / 2 auto 0;
    }

    ol li {
        list-style: decimal;

        ol li { list-style-type: lower-roman; }
        ul li { list-style-type: circle; }
    }

    ul li {
        list-style: disc;

        ul li { list-style-type: circle; }
        ol li { list-style-type: decimal; }
    }

    form {
        ol, ul {
            padding: 0;
        }

        li {
            list-style: none !important;
        }
    }

    p {
        font-size: 1em;

        &:empty {
            display: none;
        }
    }

    a {
        @extend %text-hover;
        text-decoration: underline;
    }

    // Media
    img {
        // border-radius: $corner;
        margin-top: $gap * 1.5;
        object-fit: cover;
        width: 100%;
    }

    .fluid-width-video-wrapper {
        border-radius: $corner;
        overflow: hidden;
    }

    a img {
        @extend %image-hover;
    }

    // Buttons
    .btn,
    .button {
        font-size: 1rem;
        margin-top: $gap;
        
        + .btn, .button {
	        @include break(small) {
		        margin-left: $gap;
	        }
        }
    }

    .btn a,
    .button a,
    a.btn,
    a.button {
        border: none;
        box-shadow: none;
        // color: $white;
        text-decoration: none;
        @extend %button;
        background: $secondary;
        color: $primary;
    
        &:hover {
            background: $secondary-light;
        }
    
        &:active {
            background: $secondary-dark;
        }
    }

    p.btn,
    p.button {
        padding: 0;
    }

    .btn a,
    .button a {
        display: block;
        padding: $gap / 2 $gap;
    }

    // Quotes
    blockquote {
		background-color: $white;
		border-radius: $corner;
		margin: $gap * 2 0;
        padding-bottom: $gap * 2;
		position: relative;
        
        &:before {
            background-color: $secondary;
            border-bottom-right-radius: $corner;
            border-top-right-radius: $corner;
            content: '';
            height: 75%;
            left: 0;
            position: absolute;
            top: 50%;
            transform: translate(0%, -50%);
            width: 20px;
        }
			    
	    p {
	        display: block;
            font-size: 1.25rem;
	        font-weight: $light;
	        padding: $gap * 2 $gap * 2 0 $gap * 3.5;	        
			quotes: "“" "”" "‘" "’";
            
            @include break(small) {
                font-size: 1.65rem;                
            }
            
            @include break(medium) {
                font-size: 1.85rem;                
            }
			
			&:before {
			    content: open-quote;
			    display: inline-block;
			}
			&:after {
			    content: close-quote;
			    display: inline-block;
			}
            
            &.cite {
                font-size: 1rem;
                font-weight: $bold;
                margin-top: 0;
                padding-top: $gap;
                
                @include break(small) {
                    font-size: 1.05rem;     
                }
                
                @include break(medium) {
                    font-size: 1.1rem;                
                }             
            }
	    }
    }

    code {
        background: $grey95;
        border-radius: $corner;
        border: 1px solid $grey80;
        font-family: $font-meta;
    }
    

sub, sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sup {
	top: -0.5em;
}

sub {
	bottom: -0.25em;
}

    @include break(small) {
        p, li { font-size: 1.125em; }
        li li { font-size: 1em; }
    }

    @include break(medium) {
        .alignleft,
        .float-left,
        .float_left {
            float: left;
            margin: $gap * 1.5 $gap * 1.5 $gap * 1.5 0;
            max-width: 48%;
        }

        .alignright,
        .float-right,
        .float_right {
            float: right;
            margin: $gap * 1.5 0 $gap * 1.5 $gap * 1.5;
            max-width: 48%;
        }
    }
}

@if $wordpress {
    .wp-caption {
        position: relative;
        width: auto !important;
        
	    &-text {
	        @extend figcaption;
	    }
    }
}

.wp-caption {
    position: relative;
    width: auto !important;
    
    &-text {
        @extend figcaption;
        font-size: 1.2rem !important;
    }
}

@if $drupal {
    figure[role=group] {
        position: relative;
        width: auto !important;
        
        img {
            width: 100%;
        }
        
	    figcaption {
	        @extend figcaption;
	    }
    }
}

pre {
	background-color: $grey95;
	border-radius: 3px;
	font-size: 85%;
	font-family: $font-meta;
	line-height: 1.45;
	margin: $gap * 3 0;
	overflow: auto;
	padding: $gap;
}

p {
	pre {
		display: inline-block;
		padding: 0;
	}
}
