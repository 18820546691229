/* +++++++++++++++++
+++ LAYOUT: HOME +++
+++++++++++++++++ */

.home {
	.hero-title {
		font-family: $font-header !important;
		line-height: 1;
		font-size: 2.25rem !important;
	
		@include break(large) {
			font-size: 3.75rem !important;
		}
	}
}





.slick-dots {
	button {
		@extend %transition;
		
		&:before {
			@extend %transition;
			font-size: 0.9rem !important;
		}
		
		&:hover {
			box-shadow: none;
			transform: translateY(0);
			
			&:before {
				font-size: 1.05rem !important;
			}
		}
	}
}

.slick-arrow {
	box-shadow: $shadow;
	min-height: 65px;
	width: 65px;
	position: relative;
	border: 0 !important;
	
	&:before {
		min-width: 18.77px !important;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 1 !important;
	}
	
	&:hover {
		transform: translateY(-50%);
		background: $secondary !important;
	}
	
	&:active {
		background: $secondary-dark !important;
	}
}

.slick-prev {
	left: 0 !important;
	
	@include break(xlarge) {
		left: -3rem !important;		
	}
	
	&:before {
		content: url(/assets/svg/icons/arrow-left-2.svg) !important;
		transform: translate(-65%, -50%);
	}
}

.slick-next {
	right: 0 !important;
	
	@include break(xlarge) {
		right: -3rem !important;	
	}
	
	&:before {
		content: url(/assets/svg/icons/arrow-right-2.svg) !important;
		transform: translate(-35%, -50%);
	}
}




.cards-slider {
	@include break(medium) {
		width: 102.5%;
		margin-left: -1.25%;
	}
	
	.slick-slide {
		@extend %flex;
		align-items: center;
	}
}


.card-slide {
	@extend %flex;
	@extend %transition;
	overflow: hidden;
	width: 100%;
	padding: $gap * 1.5 $gap * 1.1;
	
	&:hover {
		transform: translateY(-5px);
		
		.card-slide-header-title {
			text-decoration: underline;
		}
		
		.card-slide-wrap {
			// border-color: $secondary;
		}
	}
	
	&-wrap {
		@extend %card;
		border: 1px solid transparent;
		box-shadow: $shadow;
		padding: $gap / 2;
		
		@include break(tablet) {
			margin: 0 $gap;
		}
		
		// @include break(medium) {
			min-height: 400px;
		// }
	}
	
	.card-slide-header-title {
		@extend %transition;
		font-size: 1.75rem;
		font-weight: $bold;
		
		&.hovered,
		&:hover {
			color: $secondary-shade;
			text-decoration: underline;
		}
	}

	&-image {
		border-radius: $corner $corner 0 0;
		display: block;
		margin: 0;
		overflow: hidden;
		position: relative;
		width: 100%;
		
		@include break(tablet) {
			height: 16rem;
		}	

		img {
			@extend %object-fit;
		}
	}

	&-wrap {
		align-self: stretch;
		height: 100%;
		margin: 0;
		
		&-inner {
			padding: $gap * 1.5;
			
			.metalabel {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding-bottom: $gap * 1.15;
				border-bottom: 1px solid $grey80;
				margin-bottom: $gap * 1.5;
				
				&:before {
					content: ' ';
					display: inline-block;
					width: 16px;
					height: 16px;
					border-radius: $corner;
					background-color: $lime;
					margin-right: $gap / 2;
				}
				
				a {
					margin-left: 0;
				}
			}
			
			.card-slide-icon {
				margin-left: 0;
				margin-bottom: $gap;
			}
		}
	}

	&-header {
		margin-top: 0;
		
		+ .card-slide-summary {
			color: $primary-light;
			font-size: 1.15rem;
			margin-top: $gap;
		}

		&-title {
			font-size: 1.6em;
			
			a {
				&:hover {
					text-decoration: underline;
				}
			}
			
		}

		&-date {
			@extend %font-meta;
			display: block;
			margin-top: $gap / 2;
		}
	}
	&-button {
		&-outer {
			margin-top: $gap;
		}
	}
}






.pills {
	@extend %flex;
	margin-top: $gap * 1.5;
	
	.pill {
		@include flexbox(33.33%);
		text-align: center;
		
		&-link {
			@extend %transition;
			background-color: #F7F9DD;
			border-radius: $corner;
			display: block;
			font-size: 1rem;
			font-weight: $bold;
			margin-bottom: $gap;
			padding: $gap * 1.5 $gap / 2;
			width: 95%;
			
			@include break(small) {
				font-size: 1.25rem;
			}
			
			@include break(medium) {
				font-size: 1.75rem;
				margin-bottom: $gap * 2;
			}
			
			&:hover {
				background-color: darken(#F7F9DD, 20%);
			}
			
			&:active {
				background-color: $secondary;
			}
		}
		
		&:nth-of-type(1),
		&:nth-of-type(4) {			
			.pill-link {
				margin-left: 0;
			}
		}
		
		&:nth-of-type(3),
		&:nth-of-type(6) {			
			.pill-link {
				margin-right: 0;
			}
		}
	}
}

.pills-filter {
	background-color: $lightest-blue !important;
	border-radius: $corner;
	color: $primary;
	margin-top: $gap;
	padding: $gap $gap 0;
	
	.filter-block {
		&:first-of-type {
			min-width: 100%;
			
			@include break(medium) {
				min-width: auto;
			}
		}
		&:last-of-type {
			text-align: center;
			
			.filter-submit {
				max-width: 250px;
				margin: 0 auto;
			}
			
			@include break(small) {
			}
			
			@include break(medium) {
				padding-top: $gap * 1.5;
			}
		}
	}
	
	.filter {
		max-width: 100%;
		padding: $gap $gap $gap * 2;
		
		@include break(medium) {
			padding: 0 $gap $gap * 2;
		}
	}
	
	input.filter-field {
		@include break(small) {
			width: 100%;
		}
		
		@include break(tablet) {
			margin-bottom: $gap * 1.5;			
		}
		
		@include break(medium) {
			margin-bottom: 0;
		}
		
		@include break(large) {
			width: 400px;
		}
	}
	
	@include break(medium) {
		// padding: $gap $gap $gap * 2 $gap;
	}
	
	&-title {
		color: #335B63;
		font-family: $font-body;
		font-size: 2rem;
		padding: $gap * 1.5 $gap * 2 0 $gap * 2;
	}
	
	label + select {
		margin: 0;
	}
}