/* ++++++++++++++++++++++++
+++ LAYOUT: MEDIA BLOCK +++
++++++++++++++++++++++++ */

.media-block {
	@extend %flex;
	align-items: center;
	background-color: transparent;
	border-radius: $corner;
	
	&-outer {
		+ .media-block-outer {
			padding-top: 0;
			
			&.dark {
				padding-top: $gap * 2;
				
				@include break(medium) {
					padding-top: $gap * 4;
				}
			}
			&.padding-large {
				padding: $gap * 3 0;
			
				@include break(medium) {
					padding: $gap * 8 0;
				}
			}
		}
		&.right {
			.media-block {
				@include break(medium) {
					flex-flow: row-reverse nowrap;
					
					&-info {
						padding-left: 0;
					}
				}
				&-content {
					@include break(medium) {
						padding-right: $gap * 2;
					}
				}
			}
		}
		
		&.dark,
		&.primary {
			.media-block {
				&-info {
					color: $white;
				}
			}
			+ .media-block-outer {
				padding-top: $gap * 2;
				
				@include break(medium) {
					padding-top: $gap * 4;
				}
			}
		}
		
	    &.secondary {
		    .media-block {
				&-info {
					color: $primary;
				}
				
				.button {
					@extend %button-dark;
				}
		    }
	    }
		
	    &.video {
		    .media-block {
			    min-height: auto;   
			    
			    &-element {
				    @include flexbox(100%);
					overflow: hidden;
					
					@include break(medium) {
					    @include flexbox(55%);
					}
					
				    .video-outer {
						display: block;
						padding-bottom: 56.25%; /* 16:9 */
						height: 0;
						position: relative;
			
						iframe {
							height: 100%;
							left: 0;
							position: absolute;
							top: 0;
							width: 100%;
						}   
				    }
			    }
			    &-info {
					@include break(medium) {
					    @include flexbox(45%);
					}
			    }
		    }
	    }
	}
	
    &-element, &-info {
	    margin: 0;
    }
    &-info {
	    @extend %flex;
		align-items: center;
	    color: $primary;
		padding: $gap * 2 0 0;
	    
	    @include break(small) {
			padding: $gap * 2 0 0;
	    }
	    
	    @include break(medium) {
		    @include flexbox(45%);
			padding: 0 0 0 $gap * 3;
		}
	    
	    .button {
		    margin-top: 1rem; 
	    }
    }
    &-content {
	    p {
		    padding-bottom: $gap;
			
			.dark & {
				color: $grey80;
			}
		    
		    a {
			    @extend %text-hover;
			    text-decoration: underline;
		    }
	    }
    }
    &-element {
	    border-radius: $corner;
	    overflow: hidden;
	    position: relative;
		height: 30vh;
	    
	    @include break(medium) {
		    @include flexbox(55%);
			height: 343px;
	    }
	    
	    &-play {
		    @extend .button;
		    @include centerer(true, true);
		    background-color: $red;
			border-radius: 12px;
		    display: inline-block;
			color: $white;
		    width: auto;
		    z-index: 2;
			
			&:hover {
				color: $primary;
				transform: translate(-50%, -50%);
			}
	    }
	    
	    img {
            height: 100%;
            object-fit: cover;
            width: 100%;
            
            @include break(medium) {
	            border-radius: 0;
	            left: 0;
	            position: absolute;
	            top: 0;
            }

            .no-objectfit & {
                width: auto;
            }
	    }
    }
    &-title {
	    font-size: 2rem;
		font-weight: $bold;
		line-height: 1.25;
	    padding-bottom: $gap / 1.5;
	    
		a {
			@extend %transition;
			
			&:hover {
				color: $primary;
			}
		}
    }
}
