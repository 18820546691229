/* ++++++++++++++++++++++
+++ LAYOUT: PULLQUOTE +++
++++++++++++++++++++++ */

.pullquote {
	@extend %flex;
	font-family: $font-quote;
	justify-content: center;
	margin: 0 auto;
	padding: $gap * 1.5 0;
	text-align: center;
	
	@include break(small) {
		align-items: center;
		text-align: left;
	}
	
	@include break(medium) {
		padding: $gap * 2 $gap * 3;
	}
	
	@include break(xlarge) {
		margin: 0 auto;
		padding: $gap * 2 0;
		width: $page-width;
	}

	&-image {
		@include flexbox(75%);
		
		@include break(small) {
			@include flexbox(40%);
		}
		
		@include break(medium) {
			@include flexbox(20%);		
		}
		
		img {
			border-radius: 100%;
		}
		
		+ .pullquote-info {
			@include flexbox(100%);
			padding: $gap * 1.5 0 0 0;
			
			@include break(small) {
				@include flexbox(60%);
				padding: 0 0 0 $gap * 2;
			}
			
			@include break(medium) {
				@include flexbox(80%);
				padding: 0 $gap * 2;			
			}		
			
			blockquote {
				font-size: 1.3em;
				
				@include break(medium) {
					font-size: 1.8em;
				}	
			}
		}
	}	
	&-info {
		@include flexbox(100%);
	}
	
	blockquote {
		font-size: 1.8em;
		font-weight: $bold;
		padding-bottom: $gap / 1.5;
		quotes: "“" "”" "‘" "’";
		
		@include break(medium) {
			font-size: 2.6em;		
		}
		
		&:before {
		    content: open-quote;
		    display: inline-block;
		}
		&:after {
		    content: close-quote;
		    display: inline-block;
		}
	}
	cite {
		color: $primary;
		font-size: 1em;
		font-style: normal;
		
		@include break(medium) {
			font-size: 1.4em;
		}
	}
	&-share {
		display: block;
		position: relative;
		
		@include break(medium) {
			display: inline-block;
			padding-left: $gap * 5;
			top: -4px;
		}
		
		&-title {
			@extend %font-content;
			color: $grey30;
			font-size: 0.7em;
			font-weight: $normal;
			padding: 0 0 0 $gap * 4;
			position: relative;
			text-transform: uppercase;
			
			&:before {
				background-color: $grey30;
				content: '';
				display: block;
				height: 1px;
				left: 0;
				position: absolute;
				top: 6px;
				width: 36px;
			}
		}
		&-element {
		    @extend %transition;
		    background-color: $twitter;
		    border-radius: 100%;
		    color: $white;
			display: inline-block;
		    height: 30px;
		    margin: 0 0 0 $gap / 2;
		    text-align: center;
		    width: 30px;
		    
		    .icon {
			    margin-top: 3px;
		    }
		}
	}
}






.pullquote-standalone {
	.section-header-title {
		@extend %h2;
		font-family: $font-header;
		text-align: center;
		margin-bottom: $gap;
	}
	
	.pullquote {
		flex-direction: column;
		padding: 0;
		text-align: center;
		width: 100%;
		
		&-image {
			// @include flexbox(100%);
			clip-path: circle(100px at center);
			height: 193px;
			width: 193px;
			margin-bottom: $gap * 1.5;
			
			img {
				height: 100%;
				object-fit: cover;
			}
		}
		
		&-info {
			max-width: 100%;
			
			blockquote {
				font-size: 1.75rem;
				font-weight: $light;
				line-height: 1.25;
				
				@include break(medium) {
					font-size: 2.75rem;
				}
				
				@include break(large) {
					font-size: 3.75rem;
				}
			}
			
			cite {
				margin-top: $gap * 2;
			}
		}
	}
}