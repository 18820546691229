/* +++++++++++++++++
+++ LAYOUT: HERO +++
+++++++++++++++++ */

.hero {
	@extend %flex;
	background-color: $primary;
	position: relative;
	
	.event-styles & {
		background-color: $primary-light;
	}
	
	.hero-shape & {		
		@include break(medium) {
			max-height: 525px;
		}
	}

	@include break(medium) {
		flex-flow: row-reverse nowrap;		
	}
	
	&-outer {
		padding: 0;
		
		&.full {
			.hero-image {
				@include flexbox(100%);
				height: 100%;
				width: 100%;
				
				@include break(tablet) {
					left: 0;
					position: absolute;
					top: 0;
				}
				
				+ .hero-info {
					@include flexbox(100%);
					background-color: rgba($white, 0.5);
					box-shadow: $shadow;
					position: relative;
					text-align: center;
					z-index: 2;
					
					@include break(tablet) {
						@include flexbox(80%);
						border-radius: $corner;						
						margin: 10em auto;
					}
					
					@include break(medium) {
						@include flexbox(50%);
					}
					
					.hero-inner {
						color: $black;
						margin: 0;
						padding: 3rem;
						width: auto;
					}	
				}
			}
			
			.hero-summary {
				p {
					padding: 0;
				}
			}
		}
		
		&.solid {
			.hero-image {
				+ .hero-info {
					background-color: transparent;
					box-shadow: none;
					
					@include break(tablet) {
						@include flexbox(92%);
						margin: 25em auto 0;
					}
					
					@include break(medium) {
						@include flexbox(80em);
					}
					
					.hero-inner {
						box-shadow: $shadow;
						background-color: $primary;
						color: $white;
						text-align: left;
						
					    @include break(medium) {
						    @include flexbox(64%);
						    margin-left: $gap * 2;
					    }
					}	
				}
			}
		}
	}
	
	&-info, &-image {
		margin: 0;
	}
	
	&-info {
		@extend %flex;
		@include flexbox(100%);
		align-items: center;
		position: relative;
		z-index: 0;
		
		.hero-shape & {
			@include break(medium) {
				&:before {
					background-image: url('/assets/images/shape-dark.svg');
					background-repeat: no-repeat;
					background-size: 100% 100%;
					width: 100%;
					right: -60%;
					height: 110%;
					content: '';
					position: absolute;
					z-index: -1;
					
					.event-styles & {
						background-image: url('/assets/images/shape-dark-2.svg');
					}
				}
			}
		}
		
		.hero-label {
			font-size: 1rem;
			font-weight: $bold;
			margin-bottom: $gap * 1.25;
			
			span {
				background-color: $lightest-blue;
				border-radius: 4px;
				color: $primary;
				padding: $gap / 6 $gap / 2;
			}
		}
		
		.hero-title {
			@extend %h2;
			font-family: $font-header;
			font-weight: $normal;
			margin-left: 0;
			// max-width: 396px;
			line-height: 1.25;
		}
		
		.hero-summary {
			font-weight: $bold;
			margin-left: 0;
			max-width: 457px;
			padding-top: $gap;
			
			@include break(medium) {
				font-size: 1.25rem;
			}
		}
		
		a {
			margin-top: 2rem;
			
			&:not(.button) {
				text-decoration: underline;
			}
		}
	}
	
	&-inner {
		color: $white;
		margin: 0 auto;
		padding: 3rem 2rem;
		
		@include break(tablet) {
			width: 60em;
		}
		
		@include break(medium) {
			padding: 4rem 3rem 4rem 2em;		
		}
	}
	
	&-image {
		overflow: hidden;
		width: 100%;
		
		@include break(medium) {
			@include flexbox(55%);
		}
		
		+ .hero-info {
			@include break(medium) {
				@include flexbox(45%);
			}
			
			.hero-inner {
				margin: 0;
				
				@include break(medium) {
					margin: 0 0 0 auto;
					width: 30em;
				}
			}
		}
		
		img {
			@extend %object-fit;
		}
	}
}