/* ++++++++++++++++++++
+++ LAYOUT: SECTION +++
++++++++++++++++++++ */

// A section is a grouping of content, typically with a header, possibly with a footer. A '.section' with a '.container' results in a consistent paddings, margins and max-width for content while allowing for full width background colors on the section and centred background colors on the container.

.section {
    @extend %section;
    
    &.overflow {
        margin-top: $gap * 7;
        overflow: visible;
        
        @include break(medium) {
            margin: $gap 0;
        }
        
        @include break(large) {
            margin: $gap * 2 0 $gap * 3;
        }
    }
    
    &.padding-large {
        padding: $gap * 3 0;
    
        @include break(medium) {
            padding: $gap * 8 0;
        }
    }

    &.dark,
    &.primary,
    &.secondary,
    &.blue {
        color: $white;

        input,
        textarea,
        select {
            border-color: transparent;
        }
        
        .event-styles & {
            background-color: $primary-light;
        }
    }

    &.white { background: $white; }
    &.grey { background: $grey90; }
    &.blue { background: $blue; }
    &.dark { background: $primary; }
    &.light { background: $lightest-blue }
    &.lightest { background: $light-grey }
    &.primary { background: $primary; }
    &.secondary { background: $secondary; }
    
    &.light {
        .section-header {
            .button {
                @extend %btn-dark;
            }
        }
    }
    
    &.subnav {
	    background: $white;
    }
    
    &.sec-links {
	    border-bottom: solid 2px rgba($primary, 0.05);
	    padding-bottom: $gap * 1.5;
	    padding-top: $gap * 1.5;
	    
	    .container {
		    max-width: none;   
			padding: 0;
	    }
	    &-outer {
		    padding-bottom: 0;
		    
		    .container {
			    padding: 0;
		    }
	    }
    }    

    &-header {
        @extend %flex;
        margin: 0 auto $gap;
        
        @include break(tablet) {
            padding: 0 $gap * 2;
        }
        
        @include break(medium) {
            margin-bottom: 0;
        }
        
        &-column {
            @include flexbox(100%);
            margin-left: 0;
            
            @include break(tablet) {
                @include flexbox(60%);
            }
            
            .text-center & {
                margin: 0 auto;
            }
            
            &:nth-of-type(2) {
                @extend %flex;
                @include flexbox(100%);
                margin-left: 0;
                
                @include break(tablet) {
                    @include flexbox(40%);                
                }
                align-items: flex-end;
                margin-right: 0;
                margin-top: $gap;
                
                * {
                    width: auto;
                    margin-left: 0;
                }
                
                @include break(tablet) {
                    align-items: flex-start;
                    margin-top: 0;
                    
                    * {
                        margin-right: 0;
                        margin-left: auto;
                    }
                }
            }
        }
        
        &-title {
            @extend %h1;
            // margin-bottom: $gap;
            text-align: left;
            
            @include break(small) {
                margin-bottom: 0;
            }
            
	        + .section-header-summary {
		        padding-top: $gap / 1.5;
                // margin-bottom: $gap / 2;
                
                @include break(medium) {
                    // margin-bottom: $gap * 2;
                }
	        }
        }

        &-summary {
            margin-left: 0;
            margin-bottom: 0;
            
            @include break(small) {
	            font-size: 1.25rem;
            }
            
            @include break(medium) {
                // margin-bottom: $gap * 2;
            	// padding: 0 0 $gap;
                padding-bottom: $gap;
            	width: $half-column;
            }
        }
    }

    &-footer {
        @extend %container;
        margin-top: $gap;
        text-align: center;
        
        @include break(small) {
	        margin-top: $gap * 2;
        }
    }
    
    &.listing {
	    .card-image {
		    display: none;
	    }
    }
    
    &.circle-1 {
        background: url('/assets/images/circle-1.svg') no-repeat center;
        background-position: 65% 50%;
    }
    
    &.circle-2 {
        background: url('/assets/images/circle-2.svg') no-repeat center;
        background-position: 85% 175%;
    }
    
    &.circle-3 {
        background: url('/assets/images/circle-3.svg') no-repeat center;
        background-position: 15% 50%;
    }
    
    &.circle-4 {
        background: $lightest-blue url('/assets/images/circle-3.svg') no-repeat center;
        background-position: 85% 100%;
    }
    
    &.pullquote-standalone {
        background: url('/assets/images/quotation-marks.svg') no-repeat center, $lightest-blue;
        background-position: 50% 50%;
    }
}

.container {
    @extend %container;

    &.centered { text-align: center; }
    &.small { max-width: $two-thirds-column; }

    &.white,
    &.grey,
    &.dark,
    &.primary,
    &.secondary {
        padding: $gap;

        @include break(small) {
            padding: $gap * 2;
        }

        @include break(large) {
            border-radius: $corner;
            max-width: $page-width - $gap * 4;
        }
    }

    &.white { background: $white; }
    &.grey { background: $grey90; }
    &.dark { background: $grey15; }
    &.primary { background: $primary; }
    &.secondary { background: $secondary; }
}
