/* +++++++++++++++++++++
+++ LAYOUT: CAMPAIGN +++
+++++++++++++++++++++ */

$campaign-primary: #e3ea87;
$campaign-secondary: #f3f2f3;

%triangle {
	background-image: url('../../assets/images/triangle.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100%;
	content: '';
	display: block;
}

body.campaign {
	.header .search-button {
		background-color: $secondary;
		
		.search-icon {
			fill: $primary;
		}
	}

	.button {
		background-color: $primary;
		color: $white;
	}

	.menu-button {
		background-color: transparent;
		color: $primary;
	}
	.hero-title, .cta-title, h2, h3 ,h4 {
		font-family: $font-body;
		font-weight: $bold;
	}
	.hero {
		background-color: $campaign-secondary;
		border: solid 10px $campaign-primary;
		
		&-info {
			&:before {
				display: none;
			}
			&:after {
				@extend %triangle;
				bottom: 50px;
				height: 650px;
				position: absolute;
				left: -250px;
				transform: scaleX(-1);
				width: 520px;
				
				@include break(small) {
					bottom: -100px;
					height: 650px;
					left: auto;
					right: -250px;
					transform: scaleX();
					width: 680px;
				}
				
				@include break(medium) {
					@include centerer(false, true);
					right: -250px;
					height: 500px;
					width: 400px;
				}
				@include break(large) {
					width: 500px;
				}
			}
		}
		&-inner {
			color: $primary;
			z-index: 10;
		}
	}
	.section:not(.footer) {
		background-color: $campaign-secondary;
		// border-bottom: solid 10px $campaign-primary;
		// border-left: solid 10px $campaign-primary;
		// border-right: solid 10px $campaign-primary;
	}
	.section {
		&.blue {
			position: relative;
			color: $primary;
			
			.image-circles {
				z-index: 10;
			}
			
			&:after {
				@extend %triangle;
				@include centerer(false, true);
				height: 650px;
				position: absolute;
				transform: translate(0, -50%) scaleX(-1);
				width: 520px;
				
				@include break(small) {
					bottom: -100px;
					height: 650px;
					transform: scaleX();
					width: 680px;
				}
				
				@include break(medium) {
					@include centerer(false, true);
					right: 100px;
					height: 500px;
					width: 400px;
				}
				@include break(large) {
					width: 500px;
				}
			}
		}
	}
	.card {
		border-radius: 0;
		border: solid 5px $campaign-primary;
	}
	.wysiwyg {
		.content {
			* {
				&:first-child {
					margin-top: 0;
				}		
			}
		}
	}
	.cta {
		&:before {
			@include centerer(true, true);
			@extend %triangle;
			height: 300px;
			width: 300px;
			
			@include break(medium) {
				height: 400px;
				width: 400px;
			}
		}
	}
	.content {
		blockquote {
			border: solid 10px $campaign-primary;
			border-radius: 0;
			overflow: hidden;
			
			p {
				padding: 2rem 2rem 0 2rem;
				position: relative;
				z-index: 10;
			}
			cite {
				position: relative;
				z-index: 10;
			}
			
			&:before {
				display: none;
			}	
			&:after {
				@include centerer(false, true);
				@extend %triangle;
				height: 300px;
				right: -100px;
				transform: translate(0, -50%);
				width: 300px;
			}
		}
	}

	.signup {
		border: solid 10px $campaign-primary;
	}
}