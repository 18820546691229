/* ++++++++++++++++
+++ LAYOUT: CTA +++
++++++++++++++++ */

.cta {
    &-outer {
		position: relative;
		
		&.bg-shapes {
			background-color: $secondary;
			background: url(/assets/images/bg-lime.svg) no-repeat;
			background-size: cover;
			
			.cta-title {
				@extend %h1;
				font-family: $font-header;
				font-size: 3rem;
				padding-left: $gap;
				padding-right: $gap;
				
				@include break(small) {
					font-size: 4rem;
				}
				
				@include break(medium) {
					font-size: 5.5rem;
				}
			}
			
			.cta-summary {
				padding-left: $gap;
				padding-right: $gap;
			}	
		}
	    
	    &.full-width-image {
			background-color: $secondary;
		    padding: 0;
		    
		    @include break(tablet) {
				min-height: 70vh;		    
		    }
		    
		    .cta-image {
				position: relative;
				height: 100%;
				width: 100%;
				top: 0;
				left: 0;
			    
				@include break(tablet) {
					position: absolute;
				}
				
				img {
					border-radius: 0;
				}
				
				+ .cta-inner {
					border-radius: 100%;
					box-shadow: none;
					color: $primary;
					display: flex;
					flex-direction: column;
					text-align: center;
					justify-content: center;
					position: relative;
					
					@include break(tablet) {
						// height: 33rem;
						// margin-top: 5vw;
						// margin-right: 10vw;
						// float: right;
						width: 33rem;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						
						&:before {
							position: absolute;
							background-image: url(/assets/images/shape-circle.svg);
							background-size: cover;
							content: '';
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							width: 480px;
							height: 450px;
							z-index: -1;
						}
					}
					
					@include break(medium) {
						left: 25%;
					}
					
					@include break(tablet) {
						&:before {
							width: 530px;
							height: 500px;
						}
					}
					
					.cta-title {
						@extend %h1;
						line-height: 1;
						max-width: 400px;
						
						@include break(small) {
							font-size: 2.75rem;
						}
						
						@include break(tablet) {
							margin-top: $gap * 2;
						}
						
						@include break(medium) {
							font-size: 3.75rem;
							// margin-top: $gap * 2;
						}
					}
					
					.cta-summary {
						font-weight: $bold;
						font-size: 1.3rem;
						max-width: 400px;
					}
				}
		    }
	    }
	    
	    &.site-width-image {
		    padding: 0;
		    
		    @include break(tablet) {
				min-height: 60vh;
		    }
		    @include break(large) {
			    margin: 0 auto;
		    	width: $page-width;
		    }
		    
		    .cta-image {
				position: relative;
			    
				@include break(tablet) {
					position: absolute;
				}
		    }
	    }
	    
	    .cta-image {
		    + .cta-inner {
				// background-color: rgba($white, 0.8);
				// border-radius: $corner;
				// box-shadow: $shadow;
				color: $white;
				// margin: 4vh;
				max-width: 611px;
				text-align: left;
				padding: $gap * 1.5;
				
				.cta-title {
					font-size: 1.9rem;
				}
				
				.cta-summary {
					font-weight: $normal;
					padding-left: 0;
					padding-right: 0;
				}
				
				@include break(small) {
					// margin: 8vh;
					// padding: $gap * 3;
				}
				@include break(medium) {
					margin-left: 50vw;
					margin-right: $gap * 2;
					// margin: 8vh auto;
					// width: $page-width / 1.5;
				}
		    }
	    }
    }
	
	.dark & {
		.cta-summary {
			color: $grey80;
		}
		
		.button {
			@extend %button;
			background: $secondary;
			color: $primary;
		
			&:hover {
				background: $secondary-light;
			}
		
			&:active {
				background: $secondary-dark;
			}			
		}
	}
	
	.overflow & {
		.cta-image {
			height: 350px;
			left: 50%;
			position: absolute;
			top: 100px;
			transform: translate(-50%, -50%);
			width: 350px;
			overflow: none;
			
			&:before {
				position: absolute;
				background-image: url(/assets/images/circle-4.svg);
				background-size: cover;
				content: '';
				height: 370px;
				top: 50%;
				left: 47.5%;
				transform: translate(-50%, -50%);
				width: 393px;
			}
			
			@include break(small) {
				height: 412px;
				width: 412px;
				left: 50%;
				
				&:before {
					background-image: url(/assets/images/overlay-shape.svg);
					height: 441px;
					width: 534px;
				}
			}
			
			@include break(medium) {
				height: 490px;
				left: 25%;
				top: 50%;
				width: 490px;
				
				&:before {
					height: 535px;
					width: 648px;
				}
			}
			
			@include break(large) {
				height: 538px;
				left: 28.5%;
				width: 538px;	
						
				&:before {
					height: 578px;
					width: 700px;
				}
			}
		}
		
		.cta-inner {
			padding-top: 280px;
			text-align: center;
			
			@include break(small) {
				padding-top: 320px;
			}
			
			@include break(medium) {
				padding: $gap * 1.5;
				text-align: left;
			}
		}
	}
	
    &-inner {
	    text-align: center;
		// max-width: $page-width;
	    position: relative;
		z-index: 1;
    }
	
	&-title {
		font-weight: $bold;
	}
    
	&-summary {
		font-weight: $bold;
		max-width: 611px;
		padding: $gap * 1.5 $gap $gap * 2;
	}
 	
 	&-image {
		
		img {
			@extend %object-fit;
			border-radius: 100%;
		}
	}
	
	.button {
		@extend %button-dark;
		width: 90%;
		
		@include break(small) {
			width: auto;
		}
	}
	 
    &-caption {
	    background-color: $primary;
	    bottom: 0;
	    color: $white;
	    left: 0;
	    padding: $gap / 1.5 $gap * 1.5;
	    position: absolute;
    }
}
