//=======================================
// TYPOGRAPHY MIXINS
//=======================================

// Font size
@mixin font-size($sizeValue: 1.6) {
    font-size: $sizeValue + px;
    font-size: ($sizeValue / 16) + rem;
}

// Cursor highlighting
@mixin selection($background, $color) {

    @each $prefix in '::-moz-selection',
    '::selection' {
        #{$prefix} {
            background: $background;
            color: $color;
        }
    }
}

// mixin for placeholders
@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

@mixin placeholder {
    @include optional-at-root('::-webkit-input-placeholder') {
        @content;
    }

    @include optional-at-root(':-moz-placeholder') {
        @content;
    }

    @include optional-at-root('::-moz-placeholder') {
        @content;
    }

    @include optional-at-root(':-ms-input-placeholder') {
        @content;
    }
}



//=======================================
// LAYOUT MIXINS
//=======================================

// Centering objects (old way, flexbox preferred now)
// @include centerer(true, true);  
@mixin centerer($horizontal: true, $vertical: true) {
    position: absolute;

    @if ($horizontal and $vertical) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @else if ($horizontal) {
        left: 50%;
        transform: translate(-50%, 0);
    }

    @else if ($vertical) {
        top: 50%;
        transform: translate(0, -50%);
    }
}

//  Breakpoints
@mixin break($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media screen and (min-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }
}

// flexbox mixin - for children of flex container
@mixin flexbox($value) {
    flex: 0 $value;
    max-width: $value;
}

// Styles by number of items in list
@mixin bycount($count, $element) {

    &:first-child:nth-last-child(n + #{$count}),
    &:first-child:nth-last-child(n + #{$count})~#{$element} {
        @content;
    }
}

// @include bycount(4, article){
//     width:25%;
// }

@mixin padding($top, $right, $bottom, $left) {
    padding: $gap * $top $gap * $right $gap * $bottom $gap * $left;
}

// @include padding(0,3,0,3);


//=======================================
// ANIMATIONS MIXINS
//=======================================

// Include to give an element a transition
@mixin transition($properties...) {

    @each $prefix in -webkit-transition,
    transition {
        #{$prefix}: $properties;
    }
}

@mixin transition-ease($properties...) {

    @each $prefix in -webkit-transition,
    transition {
        #{$prefix}-property: $properties;
        #{$prefix}-duration: 0.2s;
        #{$prefix}-timing-function: ease-in-out;
    }
}

// Include to transform an element
@mixin transform($property) {

    @each $prefix in -ms-transform,
    -moz-transform,
    -webkit-transform,
    transform {
        #{$prefix}: $property;
    }
}

@mixin transform-origin($property) {

    @each $prefix in -ms-transform-origin,
    -moz-transform-origin,
    -webkit-transform-origin,
    transform-origin {
        #{$prefix}: $property;
    }
}

// filters
@mixin filter($properties) {

    @each $prefix in -webkit-filter,
    filter {
        #{$prefix}: $properties;
    }
}

// Generate keyframes with an animation name
@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }

    @keyframes #{$animation-name} {
        @content;
    }
}

// @include keyframes(fade) {
//     0% { opacity: 0; }
//     100% { opacity: 1; }
// }


// Include to give an element animation
@mixin animation($properties) {

    @each $prefix in -webkit-animation,
    animation {
        #{$prefix}: $properties;
    }
}


//=======================================
// STYLING MIXINS
//=======================================

// Gradients
@mixin linear-gradient($direction-old: left, $direction-new: to right, $colors ...) {
    background-image: -webkit-linear-gradient($direction-old, $colors);
    background-image: linear-gradient($direction-new, $colors);
}

// @include linear-gradient(left, to-right, $primary, $secondary);

@mixin radial-gradient($position, $colors ...) {
    background-image: -webkit-radial-gradient($position, $colors);
    background-image: radial-gradient($position, $colors);
}

@mixin underline($color, $height) {
    box-shadow: inset 0 (-$height + px) 0 0 $color;
    display: inline;
}


//=======================================
// PSUEDO ELEMENT MIXINS
//=======================================

// pseudo mixin
@mixin pseudo($display: block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}

// Triangle mixin
@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false) {
    @include pseudo($pos: $position);
    width: 0;
    height: 0;

    @if $round {
        border-radius: 3px;
    }

    @if $direction==down {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
        margin-top: 0 - round($size / 2.5);
    }

    @else if $direction==up {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
        margin-bottom: 0 - round($size / 2.5);
    }

    @else if $direction==right {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
        margin-right: -$size;
    }

    @else if $direction==left {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
        margin-left: -$size;
    }
}

// for example to make a red triangle that points down, with a size of 10px and rounded corners
// @include css-triangle(red, down, 10px , absolute ,true );