/* +++++++++++++++++++++++
+++ LAYOUT: PAGINATION +++
+++++++++++++++++++++++ */

// Pagination links for the listing and features pages

%pagination {
    @extend %flex;
    @extend %font-meta;
    align-items: center;
    overflow: visible;
    padding-top: 0;
    text-align: center;
    justify-content: space-between;

    a {
        @extend %transition;

        &:hover {
            background: $grey90;
        }
    }

    &-block {
        margin: 0;
    }

    &-number {
      display: block;
    }

    &-element {
        border-radius: $corner;
        display: block;
        // padding: $gap / 4 $gap / 2;
        padding: $gap / 3 $gap / 1.25;
        overflow: hidden;
        
        @include break(medium) {
            padding: $gap / 2 $gap;
        }
    }

    &-arrow {
        @extend .button;
        display: block;
        padding: $gap / 2 $gap / 1.5;
        
        @include break(small) {
            padding: $gap / 1.5 $gap * 2;
        }
    }

    &-current {
        box-shadow: 0 0 0 $line $secondary inset;
        color: $secondary;
    }

    @include break(small) {
        justify-content: center;

        &-block {
            margin: 0 $gap / 4;
        }

        &-element {
            display: block;
            padding: $gap / 2 $gap;
        }
    }
}

@if $wordpress {
    .pagination {
        @extend %pagination;

        &_block {
            @extend %pagination-block;

            &.next {
                float: right;
            }

            &.numeral {
                @extend %pagination-number;
            }

            &.view_all {
                display: none;
            }
        }

        &_element {
            @extend %pagination-element;

            &.prev {
                @extend %pagination-arrow;
                font-size: 0.8em;

                @include break(tablet){
                  font-size: 1em;
                }
            }

            &.next {
                @extend %pagination-arrow;
                font-size: 0.8em;

                @include break(small){
                  font-size: 1em;
                }
            }

            &.current {
                @extend %pagination-current;
            }
        }
    }
}
.pagination {
    @extend %pagination;
    align-items: center;

    &_block {
        @extend %pagination-block;

        &.next {
            float: right;
        }

        &.numeral {
            @extend %pagination-number;
        }

        &.view_all {
            display: none;
        }
    }

    &_element {
        @extend %pagination-element;

        &.prev {
            @extend %pagination-arrow;
            font-size: 0.8em;

            @include break(medium){
              font-size: 1em;
            }
        }

        &.next {
            @extend %pagination-arrow;
            font-size: 0.8em;

            @include break(medium){
              font-size: 1em;
            }
        }

        &.current {
            @extend %pagination-current;
        }
    }
}

@if $drupal {
    .pager {
	    padding: $gap 0;

	    &__items {
	        @extend %pagination;
	    }

        &-first { float: right; }

        &__item {
	        @extend %pagination-block;
			width: 100%;
            @include break(small) {
				width: auto;
            }

	        a {
	            @extend %pagination-element;
				padding: $gap / 2 $gap;
	        }

	        &.is-active {
	            @extend %pagination-element;
	            @extend %pagination-current;

	            a {
		            @include break(small) {
			            padding: 0;
		            }
	            }
	        }

	        &--first a,
	        &--previous a,
	        &--next a,
	        &--last a {
	            @extend %pagination-arrow;
	            @extend %pagination-block;
	        }
        }

        &-ellipsis {
            @extend %pagination-element;
        }
    }
}
.pager {
    padding: $gap 0;

    &__items {
        @extend %pagination;
    }

    &-first { float: right; }

    &__item {
        @extend %pagination-block;
        width: 100%;
        @include break(small) {
            width: auto;
        }

        a {
            @extend %pagination-element;
            padding: $gap / 2 $gap;
        }

        &.is-active {
            @extend %pagination-element;
            @extend %pagination-current;

            a {
                @include break(small) {
                    padding: 0;
                }
            }
        }

        &--first a,
        &--previous a,
        &--next a,
        &--last a {
            @extend %pagination-arrow;
            @extend %pagination-block;
        }
    }

    &-ellipsis {
        @extend %pagination-element;
    }
}
