.image-circles {
	@extend %flex;
	flex-direction: row;
	position: relative;
	height: 366px;
	// justify-content: center;
	// align-content: center;
	// align-items: center;
	
	.image-circle {
		@include flexbox(33%);
		pointer-events: none;
		position: absolute;
		transform: translate(-50%, -50%);
		height: 90px;
		width: 90px;
		
		@include break(small) {
			height: 136px;
			width: 136px;
		}
		
		&:nth-of-type(1) {
			top: 27.5%;
			left: 17.5%;
		}
		
		&:nth-of-type(2) {
			top: 40%;
			left: 50%;
		}
		
		&:nth-of-type(3) {
			top: 15%;
			left: 80%;
		}
		
		&:nth-of-type(4) {
			top: 76%;
			left: 27.5%;
		}
		
		&:nth-of-type(5) {
			top: 65%;
			left: 75%;
		}
		
		@include break(medium) {
			transform: none;
			height: 156px;
			width: 156px;
			
			&:nth-of-type(1) {
				top: auto;
				left: auto;
				top: 35px;
			}
			
			&:nth-of-type(2) {
				top: auto;
				left: auto;
				top: 85px;
				left: 185px;
			}
			
			&:nth-of-type(3) {
				top: auto;
				left: auto;
				left: 340px;
			}
			
			&:nth-of-type(4) {
				top: auto;
				left: auto;
				top: 210px;
				left: 50px;
			}
			
			&:nth-of-type(5) {
				top: auto;
				left: auto;
				top: 210px;
				left: 320px;
			}
		}
	
		img {
			@extend %object-fit;
			border-radius: 100%;
		}
	}
}

.section-two-columns {
	.container:first-of-type {
		@extend %flex;
		flex-direction: row;
		// padding: 0;
	}
	
	.section-header {
		@include flexbox(100%);
		@extend %flex;
		flex-direction: column;
		justify-content: center;
		margin: 0;
		z-index: 1;
		
		&-column {
			@include flexbox(100%);
			
			@include break(medium) {
				@include flexbox(60%);
			}
		}
		
		.button {
			margin-top: $gap;
		}
		
		@include break(tablet) {
			display: inline-block;
			width: auto;
		}
		
		@include break(medium) {
			@include flexbox(50%);
			
			.button {
				margin-top: 0;
			}
		}
		
		@include break(large) {
			@include flexbox(60%);
			
			&-title {
				font-size: 4.5rem;
			}
		}
		
		&-title,
		&-summary {
			margin: 0;
			
			@include break(medium) {
				width: 150%;				
			}
		}
	}
	
	.image-circles {
		@include flexbox(100%);
		margin-top: $gap * 1.5;
		z-index: 0;
		
		@include break(small) {
			margin-top: $gap * 3;
		}
		
		@include break(medium) {
			@include flexbox(50%);
			margin-top: 0;
		}
		
		@include break(large) {
			@include flexbox(40%);
		}
	}
}