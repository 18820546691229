/* +++++++++++++++++++
+++ LAYOUT: FOOTER +++
+++++++++++++++++++ */

// Layout of the site footer

.footer {
	background-color: $primary !important;
	border-bottom: 30px solid $secondary;
    margin-top: 0;
    // padding-bottom: 0;
    padding: $gap * 3.5 0 $gap * 2;
    z-index: map-get($zindex, footer);
	
	@include break(small) {
		padding: $gap * 3.5 0;		
	}
    
    &-header {
	    @extend %flex;
	    align-items: center;
	    // border-bottom: solid 1px $white;
	    justify-content: space-between;
	    // margin-bottom: $gap * 2;
	    padding-bottom: $gap;
		
		@include break(small) {
			padding-bottom: $gap * 2.5;			
		}
	    
		* {
			margin-top: 0;
		}
	 
	    .search {
		    @include flexbox(100%);
		    
		    @include break(tablet) {
			    @include flexbox(30%);		    
		    }
		    
	        &-input {
	            background: rgba($white, 0.1);
	            border-color: transparent;
	            color: $white;
	            font-size: 1em;
	            height: auto;
				padding: $gap / 1.5 $gap * 2.5 $gap / 1.5 $gap;
	        }
			&-icon {
				height: 24px;
				right: $gap / 1.8;
				width: 24px;
			}
			&-button {
				border-left: $line solid rgba($white, 0.1);
				width: 45px;
			}	
	    }	
	}

    &-logo {
        display: block;
        width: 100%;
		max-width: 50vw;
        
        @include break(small) {
	        display: inline-block;
	        margin: 0 auto;
	        width: auto;
			max-width: 518px;
        }
        
        img {	        
        	width: auto;
        }
    }
    
    &-nav {
	    &:first-child {
		    border-top: 0;
		    margin-left: 0;
		    padding-top: 0;
	    }
	    &:nth-child(3) {
		    @include break(small) {
		    	// margin-left: 0;
		    }
		    @include break(medium) {
		    	// margin-left: 2%;
				margin-left: 0;
		    }
	    }
	    
	    &-outer {
			@extend %flex;
			flex-direction: column;
			
			.footer-nav-row {
				@extend %flex;
				width: 100%;
				padding-bottom: $gap;
				
				&:first-of-type {
					border-bottom: solid 2px $primary-light;
					margin-bottom: $gap * 2;					
				}
				
				.footer-nav-title {
					@extend %h2;
					color: $secondary;
					font-family: $font-header;
					margin-bottom: $gap;
					padding-bottom: $gap;
					// text-align: left;
					text-align: center;
					width: 100%;
					
					@include break(small) {
						text-align: center;
					}
				}
				
				* {
					margin-top: 0;
				}
				
			}
	    }
	    
	    &.social {
		    @include flexbox(100%);
            margin: 0;
			// text-align: left;
			text-align: center;
            
            @include break(small) {
	            @include flexbox(auto);
				text-align: center;
            }
	    }
	    
        &-item {
			font-size: 1.15rem;
            margin-top: 0;
			// text-align: left;
			text-align: center;
			
			@include break(small) {
				text-align: center;
			}
            
            .footer-nav-item-title {
				@extend %h2;
				color: $secondary;
				font-family: $font-header;
	            padding-bottom: $gap;
				padding-top: $gap * 1.5;
				
				@include break(small) {
					padding-top: 0;
				}
            }
			
			span {
				display: block;
				font-weight: $bold;
			}
            
            .nav-item-link {
				line-height: 1.25;
	            margin: 0;
				padding: 0;
	            
	            &:hover {
		            color: $secondary;
	            }
				
				&:last-of-type {
					padding: 0 0 $gap 0;
					
					@include break(medium) {
						padding: 0 0 $gap * 1.5 0;						
					}
				}
            }
            
            &.social {
	            display: inline-block;
	            // margin-right: $gap / 1.5;
	            text-align: center;
				height: 46px;
				margin: $gap / 2 $gap / 4 0;
	            
	            .nav-item-link {
					@extend %transition;
					background-color: $white;
					border-radius: 100%;
					display: inline-block;
					height: 30px;
		            padding: 0;
					position: relative;
					width: 30px;
					
					@include break(medium) {
						height: 46px;
						width: 46px;
					}
					
					&:hover {
						background-color: $secondary;
						
						// .icon {
						// 	// color: $white;
						// }
					}
					
					&.instagram, &.youtube {
						.icon {
							padding: 2px;
						}
					}
					
					.icon {
						@include centerer(true, true);
						color: $primary;
						margin: 0;
						height: 22px;
						width: 100%;
						
						@include break(medium) {
							height: 34px;
						}
					}
	            }
            }
        }
    }
	
    &-nav {
	    @include flexbox(100%);
	    
	    @include break(small) {
		    @include flexbox(48%);
		    border-top: 0;
    	    // margin-left: 4%;
    	    padding-top: 0;
	    }
	    
	    @include break(tablet) {
			@include flexbox(33.33%);
			// margin-left: 2%;
			margin-bottom: $gap;
			// padding-top: $gap * 1.5;
			margin: 0;
	    }
    }
	
	a {
		@extend %transition;
		
		&:hover {
			color: $secondary !important;
		}
	}
	
	&-bottom {
		padding: $gap * 1.5 0 0;
		
		.footer-details-row {
			align-items: flex-end;
			
			.footer-details-col {
				@include flexbox(100%);
				margin-bottom: $gap / 1.5;
				
				@include break(small) {
					@include flexbox(33%);
					margin: 0;
					align-self: center;
					
					&:last-of-type {
						margin-right: 0;
						text-align: right;
					}
				}

				&:first-of-type {
					order: 1;
					margin-top: $gap;
					text-align: center;
					
					@include break(small) {
						order: 0;
						margin: 0;
						text-align: left;
					}		
				}
				&:last-of-type {
					order: 0;
					
					@include break(small) {
						order: 1;
					}		
				}

				&.livechat img {
					margin-right: auto;
				}
			}
		}
	}
	
	&-details, &-bottom {
		font-size: 0.95rem;
		
		&-row {
			@extend %flex;
			flex-direction: row;
			margin: 0;
			width: 100%;
			
			.footer-details-col {
				@include flexbox(100%);
				margin-bottom: $gap / 1.5;
				
				@include break(small) {
					@include flexbox(50%);
					margin: 0;
					
					&:last-of-type {
						margin-right: 0;
						text-align: right;
					}
				}
			}
			
			img {
				@include break(small) {
					margin-right: 0;
				}
			}
			
			.button-text {
				color: $white;
				font-size: 1.5rem;
				
				&:hover {
					color: $secondary;
				}
				
				.icon {
					vertical-align: top;
					margin-right: $gap / 2;
					margin-top: $gap / 3.5;
					width: 29px;
					height: 29px;
				}
			}
			
			&:first-of-type {
				margin-bottom: $gap * 1.25;		
			}
		}
		
		&-nav {
			@extend %flex;
			flex-direction: row;
			margin: 0;
			padding: 0;
			
			&-item {
				display: block;
				margin: 0;
				padding: 0;
				// text-align: left;
				text-align: center;
				width: 100%;
				
				@include break(small) {
					display: inline;
					width: auto;
				}
				
				.nav-item-link {
					margin: 0;
					padding: 0;
					
					@include break(small) {
						margin: 0 $gap * 2 0 0;						
					}
				}
			}
		}
	}
	
	&-details {
		border-bottom: 1px solid $primary-light;
		border-top: 1px solid $primary-light;
		padding: $gap * 1.5 0;
		text-align: center;
		
		@include break(small) {
			text-align: left;
		}
		
		.footer-details-col {
			&:nth-child(1) {
				@include break(small) {
					@include flexbox(75%);
					padding-right: $gap;
				}
				@include break(medium) {
					@include flexbox(70%);
				}
			}
			
			&:nth-child(2) {
				@include break(small) {
					@include flexbox(25%);
				}
				
				@include break(medium) {
					@include flexbox(30%);
				}
			}
		}
	}
	
	&-credits {
		background-color: $primary;
		color: $white;
		padding: $gap * 1.5 0;
		
		@include break(tablet) {
			margin-top: $gap * 3;		
		}
		
		* {
			margin-top: 0;
		}
		.container {
			@extend %flex;
		}
		p {
			@include flexbox(100%);
			
			@include break(tablet) {
				@include flexbox(50%);
			}
			
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}

}
