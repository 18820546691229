.pledge-wall {
    .cta {
      @extend .container;
      margin-top: 1.438rem;
      
      &-inner {
        background-color: $secondary;
        border-radius: 1.438rem;
        padding: 0 $gap;
        
        @include break(medium) {
          padding: $gap * 1.5 $gap 0;
        }
      }
    }
    .cta-outer {
      .cta-title,
      .cta-summary {
        color: $primary;
      }
      .cta-title {
        font-family: $font-body;
        font-weight: $bold;
        font-size: 3.2rem;
        line-height: 3.438rem;
        text-transform: uppercase;
        display: inline-block;
        position: relative;
        padding-top: 1.75rem;
        
        @include break(small) {
          font-size: 3.5rem;
          line-height: 3.938rem;
        }
        
        @include break(medium) {
          font-size: 5.5rem;
          line-height: 6.938rem;
          padding-left: 6.25rem;
        }
        
        &::before {
          @include pencil('%23e5017d');
          display: block;
          content: "";
          width: 3.875rem;
          height: 3.875rem;
          background-repeat: no-repeat;
          margin: $gap auto $gap * 2;

          
          @include break(medium) {
            margin: 0;
            position: absolute;
            top: 3.438rem;
            left: 0;
          }
        }
      }
      .cta-summary {
        @extend .container;
        font-family: $font-body;
        font-weight: $normal;
        font-size: 2rem;
        line-height: 3rem;
        padding: 0;
        padding-top: 1rem;
        padding-bottom: 3.563rem;
        
        @include break(small) {
          font-size: 2.2rem;
          line-height: 3rem;
        }
        
        @include break(medium) {
          font-size: 2.625rem;
          line-height: 3.313rem;
        }
      }
    }
    .pledge-form {
      &-title {
        font-family: $font-body;
        font-weight: $bold;
        font-size: 2.5rem;
        line-height: 3.188rem;
        color: $black;
        text-align: center;
        margin: 0 0 $gap;
        width: 100%;
        
        @include break(tablet) {
          margin: $gap * 3 0;
        }
        
        span {
          color: $red;
        }
      }
      
      .gform_submission_error {
        background-color: $red;
        border-radius: $corner;
        color: $white;
        font-size: 1.2rem;
        padding: $gap / 2 $gap;
      }
      .validation_message {
        color: $red;
        padding-top: $gap / 2;
      }
      .gfield_required {
        color: $red;
        font-style: italic;
        padding-left: 2px;
      }
      
      .ginput_container_textarea textarea {
        background: #F5F8D5;
        height: 3rem;
        min-height: 3rem;
      }
      .gform_fields {
        @extend %flex;
        justify-content: space-between;
        
        .ginput_complex {
          @extend %flex;
          @include flexbox(100%);
          justify-content: space-between;
          
          span {
          @include flexbox(100%);
          margin: 0;
          
          @include break(tablet) {
            @include flexbox(48%);
          }
        }
        }
        
        .gfield {
          @include flexbox(100%);
          justify-content: space-between;
          margin: 0;
          padding-top: $gap / 1.5;
          
          @include break(tablet) {
            @include flexbox(48%);
          }
          
          &:first-child {
            @include flexbox(100%);
            ::-webkit-input-placeholder {
              color: $black;
            }
            :-moz-placeholder {
              opacity: 1;
              color: $black;
            }
            ::-moz-placeholder {
              opacity: 1;
              color: $black;
            }
            :-ms-input-placeholder {
              color: $black;
            }
            input:-moz-placeholder {
              color: $black;
            }
          }
          &:nth-child(2) {
            @include flexbox(100%);
          }
        }
      }
      .ginput_container_checkbox {
        padding-top: $gap * 1.5;
        @include break(tablet) {
          bottom: -2rem;
          padding-top: 0;
          position: relative;
        }
        
        [type="checkbox"] {
          background-color: $primary !important;
          border-radius: 4px !important;
          height: 1.8rem !important;
        }
        label {
          font-weight: $bold;
        }
      }
      .gform_footer {
        padding-top: $gap * 1.5;
        @include break(tablet) {
          padding-top: 0;
          text-align: right;
        }
      }
    }
    .pledge-thank-you {
      .container {
        @extend .container;
        padding: 0;
        margin-top: 4.75rem;
        margin-bottom: 5rem;
        text-align: center;
        h2 {
          font-family: $font-header;
          font-weight: $normal;
          font-size: 5.5rem;
          line-height: 5.5rem;
          color: $black;
          padding-bottom: 3.625rem;
        }
        p {
          font-weight: $normal;
          font-size: 1.25rem;
          line-height: 1.875rem;
          padding-bottom: 3rem;
          &:first-child {
            font-family: $font-body;
            font-weight: $normal;
            font-size: 1.25rem;
            line-height: 1.563rem;
            padding-bottom: 0;
          }
        }
        .social .nav-item-link {
          background: $secondary;
        }
        ul.social {
          padding-bottom: 6.188rem;
        }
        button.view-petition {
          background: none;
          text-decoration: underline;
          &:hover {
            color: #e5017d;
            background: none;
            transform: none;
            box-shadow: none;
          }
        }
      }
    }
    .pledge-cards {
      .section-header-column {
        @extend .container;
        flex: auto;
        margin: 0 auto;
        .section-header-title,
        .section-header-summary {
          color: $black;
          text-align: center;
        }
        .section-header-summary {
          font-family: $font-body;  
          font-weight: $normal;
          font-size: 1.25rem;
          line-height: 1.625rem;
          width: 100%;
          padding-bottom: 3.625rem;
        }
        .section-header-title {
          font-family: $font-header;
          font-weight: $normal;
          font-size: 5.5rem;
          line-height: 7.063rem;
          padding-bottom: 3.188rem;
        }
      }
      .cards {
        .card {
          pointer-events: none;
          &.hovered,
          &:hover {
            // border-color: $secondary;
            box-shadow: $shadow;
            transform: none;
          }
        }
        .card-wrap {
          background-color: $secondary;
          width: 100%;
          .card-summary {
            color: $primary;
            padding-bottom: $gap;
            
            @include break(tablet) {
              padding-bottom: 7.438rem;
            }
          }
          .card-name,
          .card-header-date {
            color: #e5017d;
            text-align: right;
            font-size: 1.25rem;
            margin-top: 0;
          }
          .card-name {
            font-weight: $bold;
          }
        }
        &-footer {
          p {
            text-align: center;
            font-family: $font-body;
            font-weight: $bold;
            font-size: 2rem;
            line-height: 2.563rem;
            color: $black;
            margin-top: 7.438rem;
            margin-bottom: 7.563rem;
          }
        }
      }
    }
  }