/* ++++++++++++++++++++
+++ LAYOUT: WYSIWYG +++
++++++++++++++++++++ */    
    
.wysiwyg {
	margin: 0;
	
	&.center {
		.content {
			margin: 0 auto;		
		}
	}
	
	.content {
		margin: 0;
		
		@include break(medium) {
			width: 66.66%;	
		}		
	}
	&-outer {
		&.green {
			background-color: #E1EAD2;
			
			.content {
				h2, h3, h4, h5, p, ul, ol {
					color: $black;
				}
				.button {
					@extend .button-dark;
				}
			}
		}
		&.lime-green {
			background-color: #E4EE81;
			
			.content {
				.button {
					@extend .button-dark;
				}
			}
		}
		&.light-blue {
			background-color: #CCE6E9;
			
			.content {
				.button {
					@extend .button-dark;
				}
			}
		}
		&.grey {
			background-color: #F0F0F1;
		}
	}
}