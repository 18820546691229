/* +++++++++++++++++++++++++++
+++ LAYOUT: RESPONSIVE NAV +++
+++++++++++++++++++++++++++ */

// Layout and styling of the navigation at mobile and tablet sizes

.rnav {
    background: $primary;
    color: $white;
    margin-top: 0;
    padding-top: 0;

    &-outer {
        height: 0;
        overflow: hidden;
        transition: height 500ms ease;
        
        @include break(large) {
            display: none;
        }
    }

    &-search {
        background: $primary;
        padding: $gap;
        
        .search {
            width: 100%;
            
            &-input {
                background: rgba($white, 0.1);
                border-color: transparent;
                color: $white;
                font-size: 1em;
                height: auto;
                padding: $gap / 1.5 $gap * 2.5 $gap / 1.5 $gap;
            }
            &-icon {
                height: 24px;
                right: $gap / 1.8;
                width: 24px;
                fill: $primary !important;
            }
            &-button {
                border-left: $line solid rgba($white, 0.1);
                width: 45px;
                
                &:hover {
                    transform: translateY(0);
                }
            }	
        }	
    }

    &-item {
        border-top: $line solid rgba($white, 0.1);
        position: relative;

        &-link {
            @extend %transition;
            display: block;
            padding: $gap $gap * 3 $gap $gap;
            text-align: left;

            &:hover {
                background: rgba($white, 0.1);
            }
            
            .icon {
                margin-right: $gap / 1.5;
            }
        }
    }

    &-dropdown {
        background: rgba($black, 0.2);
        height: 0;
        overflow: hidden;
        transition: height 500ms ease;   
    }
    
    &-secnav {
        background: rgba($white, 0.1);
        
        &-item {
            border-bottom: $line solid rgba($white, 0.1);
            display: block;
            // padding: 0 $gap / 2;
            
            &:last-child {
                border-bottom: 0;
            }
            
            .rnav-item-link {
                font-size: 0.9em;
                font-weight: $normal;
                padding-left: $gap * 2;
                
                &:hover {
                    background: rgba($white, 0.2);
                    border-color: $primary;
                    // color: $primary;
                }
            }
        }
    }

    &-arrow {
        background-color: transparent;
        box-shadow: none;
        cursor: pointer;
        padding: $gap;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
        
        &:hover {
            background-color: transparent;
            box-shadow: none;
        }

        .icon {
            @include transition(transform 0.4s ease);
            display: block;
            width: $gap;
            color: $white;

            .rnav-checkbox:checked + .rnav-arrow & {
                @include transform(rotate(-180deg));
            }
        }
    }

    &-button {
        border-top: $line solid rgba($white, 0.1);
        padding: $gap;
        text-align: center;
        
        .button {
            width: 100%;
        }
        
        a {
            &:hover {
                text-decoration: underline;
            }
        }
        
        span {
            @extend %h3;
            font-family: $font-header;
            margin-right: $gap / 1.75;
            
            img {
                display: inline;
                vertical-align: middle;
                width: 30px;
                margin-top: -3px;
            }
        }
        
        @include break(tablet) {            
            .button {
                width: 35%;
            }
        }
    }

    @include break(medium) {
        #rnav-open:checked + & {
            display: none;
        }
    }
}
