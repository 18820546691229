/* ++++++++++++++++++++
+++ LAYOUT: GALLERY +++
++++++++++++++++++++ */

.gallery {
	margin: $gap * 1.5 0;
	&-item {
        margin: 0;
        text-align: center;
		
		&:hover { 
			img {
				opacity: 0.8;
			}
		}
	}
	&-thumb {
		padding: 0 1rem 0 0;
		outline: 0;
		position: relative;
		
		a {
			outline: 0;
		}
		
		img {
			@extend %transition;
			border-radius: $corner;
			position: relative;
		}
	}
	&-caption {
		color: $primary-light;
		font-size: x-small;
		margin-top: $gap / 1.5;
		
		@include break(tablet) {
			position: absolute;
			top: 17px;
			left: -35px;
			transform: rotate(-45deg);
			padding: 5px;
			width: 150px;
			background: $secondary;
			font-size: x-small;
		}
		
		
		.primary &,
		.dark &,
		.blue & {
			color: $white;
		}
	}
}
.slick-arrow {
	display: none !important;
	
	@include break(small) {
		display: block !important;
	}
}

.section {
	&.gallery-outer {
		@include break(medium) {
			padding: $gap * 4 0 $gap * 5;
		}
		
		&.secondary {
			color: $primary;
		}
	}
}

.slick-dots li.slick-active button:before,
.slick-dots li button:before {
	.primary &,
	.dark &,
	.blue & {
		color: $white;
	}
}

.lg-outer .lg-thumb-item {
	border-color: transparent !important;
}
.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover {
	border-color: $secondary !important;
}