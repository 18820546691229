/* +++++++++++++++++
+++ LAYOUT: MAIN +++
+++++++++++++++++ */

// Main is the high level container of content, in between the site header and footer

.main {
    background: $body-color;
    display: block;
    margin: 0;

    &-section {
        @extend %flex;
        @extend %section;
        background: $light-grey;
        padding-bottom: $gap * 3;
        
        align-items: flex-start;
        margin: auto;
        overflow: visible;
        
        .container {
            max-width: $page-width;            
        }
        
        form {
            @include break(medium) {
                width: $half-column;                 
            }
            
            textarea {
                resize: none;
            }
            
            label {
                &:not(:first-of-type) {
                    margin-top: 0; // causing form margin issues
                }
            }
            
            .button {
                margin-top: $gap * 1.5;
            }      
        }
    }

    &-header,
    &-footer {
        text-align: center;
    }

    &-header {
        &-title {
            margin-bottom: $gap / 1.5;
        }
        &-summary {
            p {
                font-size: 1.2em;
            }
        }
    }

    &-section {
	    @include break(medium) {
	        flex-flow: row wrap;	    
	    }

        > * {
	        @extend %flex;
			align-items: flex-start;
			flex-direction: column;
			justify-content: space-between;
	        
	        @include break(medium) {
	            flex: 1 66.66%;
				flex-direction: row-reverse;
	        }
        }
	    &.single {
            @include break(medium) {
                padding-bottom: $gap * 6;
            }
	        > * {
				flex-direction: column-reverse;
		        
		        @include break(medium) {
					flex-direction: row-reverse;
		        }
	        }
	        .sidebar {
				position: sticky;
				position: -webkit-sticky;
                margin-top: $gap * 2;
                margin-bottom: 0;
	        }
	    }
    }
}
