/* +++++++++++++++++++
+++ LAYOUT: HEADER +++
+++++++++++++++++++ */

// Site header bar
.header {
	@extend %flex;
    background: $header-color;
    position: relative;
    width: 100%;
    z-index: map-get($zindex, header);
    
    .search {
        &-button {
            border: 0;
            width: 35px;
            
            .search-icon {
                right: 24%;
            }
            
            &:hover {
                transform: translateY(0);
                box-shadow: none;
                border: 0;
                
                .search-icon {
                    fill: $primary;
                }
            }
        }
    }

    &-inner {
	    @extend %flex;
	    @include flexbox(100%);
	    justify-content: space-between;
	    // padding: $gap;
    }
    
    &-logo {
	    margin-left: 0;
        padding: $gap * 2 0 $gap * 1.25 $gap * 1.5;
        max-width: 50%;

        @include break(tablet) {
            max-width: 542px;
        }
    }

    &-links {
        display: none;
     	margin-right: 0;
        padding: $gap * 1.75 $gap * 1.5 0 0;
        
        .nav-item {
            margin-left: $gap * 1.5;
            
            a {
                &:not(.button) {
                    &:hover {
                        color: $red;
                    }
                }
            }
        }
        
        .button {
            background: $red;
            color: $white;
            text-transform: uppercase;
            font-size: 1.35rem;
            padding: $gap / 2 $gap * 1.75;
        
            &:hover {
                background: $primary;
            }
        
            &:active {
                background: $primary-light;
            }
        }
        
        span {
            @extend %h4;
            font-family: $font-header;
            margin-right: $gap / 1.75;
            
            img {
                display: inline;
                vertical-align: middle;
                width: 35px;
            }
        }
        
        ul {
            display: flex;
            align-items: center;
        }
     	
		@include break(large) {
            display: block;
		}
    }
    &-nav {
        background: $secondary;
        font-weight: $bold;
	    margin-right: 0;
        width: 100%;
        
	    &-list {
	        display: none;
	        
		    @include break(large) {
		        display: flex;
                justify-content: center;
		        margin: 0;
                
                .nav-item {
                    margin: 0;
                    
                    &:last-of-type {
                        .nav-item-link {
                            border-right: 0;
                        }
                    }
                }
		    }
            
            @include break(large) {
                justify-content: flex-start;
                
                .nav-item {
                    &:last-of-type {
                        .nav-item-link {
                            border-right: 1px solid $white;
                        }
                    }
                }
            }
            
            .nav-item-link {
                margin: 0;
                padding: $gap * 1.25 $gap * 1.25;
                border-right: 1px solid $white;
                
                &:hover {
                    color: $red;
                }
                
                @include break(small) {
                    font-size: 1.1rem;
                }
                
                @include break(medium) {
                    font-size: 1.05rem;
                    padding: $gap $gap * 1.5;                    
                }
                
                @include break(large) {
                    font-size: 1.2rem;
                    padding: $gap * 1.25 $gap * 1.65;                    
                }
            }
	    }
    }
    &-subnav {
        margin-right: 0;
        
	    &-inner {
		    @include flexbox(100%);
		    background-color: $primary;
            color: $white;
		    display: none;
		    padding: $gap / 4 $gap * 1.75;
		    text-align: right;
		    width: 100%;
            
            @include break(large) {
                display: flex;
                justify-content: flex-end;
            }
	    }
	    
	    @include break(large) {
		    display: inline-block;
		    
		    &-list {
			    text-align: right;
                margin-right: $gap * 0.75;
			    
			    .subnav-item {
				    display: inline-block;
				    
				    .nav-item-link {
					    font-size: 0.9em;
					    margin: 0 $gap * 0.75;
				    }
			    }
		    }
	    }
        
        @include break(large) {
            &-list {
                margin-right: $gap * 0.95;
                
                .subnav-item {
                    .nav-item-link {
                        margin: 0 $gap * 0.95;
                    }
                }
            }
        }
    }
}

// Navigation items in the header and footer
.nav-item {
    float: left;

    &:focus-within .dropdown, &:hover .dropdown {
        @include transition(opacity 0.2s 0.3s ease);
        opacity: 1;
        visibility: visible;

        .nav-item {
            float: none;
        }
    }

    .button {
        margin-left: $gap;
    }

    &-link {
        @extend %text-hover;
        display: block;
        margin: 0 $gap;
        padding: $gap / 2 0;

        .social & {
            margin: 0;
            padding: $gap / 2 $gap;
        }

        &.current {
            box-shadow: inset 0 -2px 0 $secondary;
        }
    }

    &-arrow {
        width: $gap;
    }
}

// Responsive nav hamburger button
.menu-button {
    background-color: transparent;
    color: $black;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: $bold;
    margin: $gap / 5 0 0 0;
    padding: 0 $gap * 1.5 0 0;
    position: absolute;
    text-transform: uppercase;
    width: auto;
    padding-right: $gap * 1.5;
    top: 30%;
    right: $gap * 1.5;
    
    &:hover {
        background-color: transparent;
        box-shadow: none;
        color: $primary; 
        transform: translateY(0);
        
        .icon {
            color: $secondary-shade;             
        }
    }
    
    @include break(tablet) {
        font-size: 1.4rem;
        top: $gap * 2;
        right: $gap * 2;
        padding-right: $gap * 2;
    }

    @include break(large) {
        display: none;
    }
    .icon {
        @include centerer(false, true);
        height: 1.25rem;
        right: 0;
        width: 1.25rem;
        margin-left: $gap;
        
        @include break(tablet) {
            height: 1.5rem;
            width: 1.5rem;
        }
    }
}

// Dropdown menu styling
.dropdown {
    @include transition(visibility 0s 0.4s ease, opacity 0.2s 0.2s ease);
    background: $header-color;
    border-radius: 0 0 $corner $corner;
    // margin-top: $gap;
    opacity: 0;
    position: absolute;
    visibility: hidden;
    
    .nav-item {
        &-link {
            border: 0;
            padding: $gap / 1.5 $gap * 1.25;
        }
    }
}

// CMS tweaks
@if $wordpress {
    #wpadminbar {
        box-sizing: content-box;
        margin-top: 0;

        img {
            display: inline;
        }

        @media screen and (max-width: 600px) {
            position: fixed;
        }
    }
}

@if $drupal {
	.header-nav-outer {
		.nav-item-link {
			&.is-active {
		      color: $grey15;
		      font-weight: $bold;
			}
		}
	}
}
