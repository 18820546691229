/* ++++++++++++++++++
+++ LAYOUT: SHARE +++
++++++++++++++++++ */

.share {
	@extend %flex;
	justify-content: center;
	
	&-item {
		@extend %flex;
		@include flexbox(100%);
		align-items: center;
		margin: 0 $gap $gap * 1.5;
		
		@include break(small) {
			@include flexbox(auto);
			margin: 0 $gap;
		}
		
		&.whatsapp {
			display: inline-block;
			
			@include break(tablet) {
				display: none;			
			}
		}
		
		h3 {
			font-size: 1.3em;
		}
		
		.button {
			@extend %flex;
			align-items: center;
			justify-content: center;
			width: 70%;
			
			@include break(small) {
				width: auto;			
			}
			
			.icon {
				margin-right: $gap / 2;
			}
			
			&.whatsapp {
				.icon {
					height: 1.2em;
					width: 1.2em;
				}
			}
		}
	}
}