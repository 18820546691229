/* +++++++++++++++++++
+++ LAYOUT: SEARCH +++
+++++++++++++++++++ */

.search {
	display: inline-block;
	margin: auto 0;
	position: relative;
	// width: 160px;
	
	&-input {
		background-color: $grey90;
		border: 0;
		color: $primary;
		// font-size: 0.9em;
		height: 1.8em;
		margin-top: 0;
		padding: 0 $gap * 2.5 0 $gap;
		
		&::placeholder {
			color: $white;
		}
		
		&:focus {
			background-color: $white;
			color: $primary;
		}
		
		@include break(medium) {
			width: 169px;
			
			&::placeholder {
				color: $primary-light;
			}
			
			&:focus {
				width: 220px;
			}
		}
	}
	
	&-icon {
	    @include centerer(false, true);
		fill: $primary;
		cursor: pointer;
		height: 16px;
		display: inline-block;
		// right: $gap / 3.5;
		right: 20%;
		pointer-events: none;
		width: 16px;
		
		&:hover {
			.search-button {
				background-color: $secondary;
			}	
		}
	}
	
	&-button {
		border-radius: $corner;
		border-left: $line solid $grey80;
		height: 100%;
		overflow: hidden;
		padding: 0;
		position: absolute;
		right: 0;
		text-indent: -999rem;
		top: 0;
		width: 28px;
		
		&:hover,
		&:active {
			.search-icon {
				fill: $white;
			}
		}
	}	
}