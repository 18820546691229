//++++++++++++++++++++++++
//+++ HELPERS: BUTTONS +++
//++++++++++++++++++++++++

// Button mixin
%button {
    @extend %transition;
    @extend %font-body;
    border-radius: $corner;
    display: block;
    // font-weight: $bold;
    padding: $gap / 1.5 $gap * 2;
    text-align: center;
    width: 100%;
    
    &:hover {
        transform: translateY(-3px);
        box-shadow: 0px $line*3 0px 0px rgba($primary,0.1);
    }

    @include break('small') {
        display: inline-block;
        width: auto;
    }
}

.btn-text,
.button-text {
    padding: 0;
    background: inherit;
    border-radius: 0;
}

.btn,
.button {
    @extend %button;
    background: $secondary;
    color: $primary;

    &:hover {
        background: $secondary-light;
    }

    &:active {
        background: $secondary-dark;
    }
}

.btn-secondary,
.button-secondary {
    @extend %button;
    // box-shadow: inset 0 0 0 $line rgba($black, 0.2);

    &:hover {
        // box-shadow: inset 0 0 0 $line currentColor;
    }

    &:active {
        background: rgba($black, 0.1);
    }
}

.btn-dark,
.button-dark,
%btn-dark,
%button-dark {
    @extend %button;
    background: $primary;
    color: $white;

    &:hover {
        background: $primary-light;
    }

    &:active {
        background: $primary-dark;
    }
}