/* ++++++++++++++++++++
+++ LAYOUT: SIDEBAR +++
++++++++++++++++++++ */

// Represents content that is tangentially related to the content nearby, such as tags and subnavigation. within the {% block page %} the sidebar is optional and if included will trigger a two column layout

.sidebar {
	@extend %transition;
	border-radius: $corner;
	margin-bottom: $gap * 2;
	width: 100%;

	@include break(small) {
		margin-left: 0;
		max-width: 60vw;
	}

	@include break(medium) {
		@include flexbox(29%);
		margin: 0;
		// top: calc(#{$header-height} + (#{$gap} * 2));
		
		top: calc((#{$gap} * 2));

		.nav-up & {
			// top: $gap * 2;
		}
	}
	
	@include break(large) {
		@include flexbox(25%);
	}
	
	&-block {
		background: $white;
	}

	&-block + &-block {
		margin-top: $gap * 3;
	}
	
	&-block + &-meta,
	&-meta + &-meta {
		padding: $gap * 1.5 0;
		background: transparent;
		
		@include break(medium) {
			padding: $gap * 1.5 0;
		}
	}
	
	&-meta {
		border-radius: $corner;
	}

	&-title {
		background-color: $grey;
		border-top-left-radius: $corner;
		border-top-right-radius: $corner;
		color: $primary;
		font: $normal 1.2em/1.2 $font-body;
		margin: 0;
		padding: $gap $gap * 1.5;

		@include break(medium) {
			// border-bottom: $line solid $grey80;
			// margin: 0 0 $gap;
			// padding-bottom: $gap / 2;
		}
	}
	
	&-image {
		border-radius: $corner $corner 0 0;
		height: 300px;
		object-fit: cover;
		width: 100%;
	}
	
	&-image + &-block {
		border-radius: $corner;
	}

	&-block {
		border-radius: $corner;
		position: relative;
		padding: $gap / 1.5 $gap * 1.5 $gap;
	}
	
	&-title + &-block,
	&-image + &-meta {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	&-nav {
		// border-top: solid 1px $grey90;
		// margin-top: $gap;
		// padding-top: $gap;

		// @include break(medium) {
			border-top: 0;
			margin: 0;
			padding-top: 0;
		// }

		&-item {
			// padding: 0 0 $gap;
			position: relative;
			
			&:last-of-type {
				padding-bottom: 0 $gap / 2;
			}
			
			.rnav-arrow {
				color: $black;
				padding: 0;
				position: absolute;
				right: 0;
				top: 0;
				// width: 50px;
				background: $primary;
				color: $white;
				
				.icon {
					float: right
				}
				
				&:hover {
					transform: translate(0);
					// box-shadow: none;
					color: $secondary-shade;
				}
			}
			
			.subnav_element {
				@extend %text-hover;
				display: block;
				font-size: 1rem;
				font-weight: $bold;
				padding: $gap / 2.5 0;

				&:hover {
					color: $secondary-shade;
					text-decoration: underline;
				}
				&.current {
					background: $secondary;
					font-weight: $bold;
					padding-left: $gap / 1.5;
					
					&:hover {
						background: $secondary-light;
						color: $primary;
						text-decoration: none;
					}
				}
			}

			&.current-menu-item {
				.subnav_element {
					color: $secondary;
					font-weight: $bold;
				}

				.sidebar-nav-item {
					.subnav_element {
						color: inherit;
						font-weight: $normal;
					}
				}
			}
		}
		&-list {
			.sub-list {
				box-shadow: inset 3.38px 0 $secondary;
				// height: 0;
				overflow: hidden;
				transition: height 500ms ease;
				
				&.show-nav {
					margin: $gap / 2 0;
					height: auto;
				}
			
				& & {
					margin-left: $gap;
				}
				
				.sidebar-nav-item {
					&:first-of-type {
						// margin-top: $gap / 2;
					}
				}
			
				.sidebar-nav-item-link {
					display: block;
					font-size: 0.9em;
					font-weight: $normal;
					
					span {
						display: block;
						padding: $gap / 2.5 $gap / 1.25;
						width: 100%;
					}
			
					&.current {
						color: $primary;
						margin-left: -$gap;
						padding-left: $gap;
						
						span {
							background: $secondary;
						}
						
						&:hover {
							span {
								background: $secondary-light;
								color: $primary;
								text-decoration: none;
							}
						}
					}
			
					&:hover {
						color: $secondary-shade;
						
						span {
							text-decoration: underline;
						}
					}
				}
			
				.sidebar-nav-item {
					padding-left: $gap;
			
					&.current-menu-item {
						.sidebar-nav-item-link {
							box-shadow: inset 2px 0 currentColor;
						}
			
						.sidebar-nav-item {
							.sidebar-nav-item-link {
								box-shadow: none;
							}
						}
					}
				}
			}
		}
	}
	
	.share-title {
		display: block;
		margin-bottom: $gap / 2;
		width: 100% !important;
	}
	
	

	&-meta {
		background-color: $white;
		margin: 0;
		position: relative;
		padding: $gap * 1.5;
		
		&:last-of-type {
			padding-bottom: 0;
			
			@include break(medium) {
				padding-bottom: $gap * 1.5;
			}
		}
		
		a {
			&:hover {
				text-decoration: underline;
			}
		}
		&-bold {
			font-weight: $bold;
		}

		&-block {
			margin: $gap / 1.5 0;

			&:first-child {
				margin-top: 0;	
			}			
			&:last-child {
				margin-bottom: 0;	
			}
			
			span {
				color: $primary;
				font-weight: $bold;
				
				&:after {
					content: ' ';
				}
			}
			
			.button {
				width: 100%;
				max-width: 300px;
			}
			
			&-tag {
				@extend %transition;
				background: $primary;
				border-radius: $corner;
				color: $white;
				display: inline-block;
				font: $normal 0.9em/1.5 $font-body;
				margin: 0 $gap / 4;
				padding: $gap / 2 $gap;
				
				
				&:first-child {
					margin-left: 0;
				}

				&:hover {
					background: $primary-light;
					box-shadow: 0px $line*3 0px 0px rgba($primary,0.1);
					transform: translateY(-3px);
				}
			}
			&.share {
				text-align: center;
				justify-content: center;
				
				@include break(small) {
					text-align: left;
					justify-content: flex-start;
				}
			}
			&.downloads {
				background-color: $white;
				color: $black;
				padding: $gap;
				width: 100%;
					
				.icon {
					display: inline-block;
					margin: 0 $gap / 3 0 0;
					width: 20px;
				}
				
				h3 {
					color: $primary;
					font-size: 1.2em;
				}
				.downloads-link {
					@extend %transition;
					display: block;
					font-size: 0.9em;
					padding: $gap / 2 0 0 $gap * 2;
					position: relative;
					
					&:hover {
						color: $primary;
					}
					
					.icon {
						left: 0;
						position: absolute;
						top: 4px;
					}
				}
			}
		}
		
		&-share {
			@extend %flex;
			margin: $gap / 2 0 $gap / 1.5;
			
			&-button {
				@extend %flex;
				@extend %transition;
				align-items: center;
				background-color: $secondary;
				border-radius: 100%;
				height: 40px;
				justify-content: center;
				margin: 0 $gap / 4;
				position: relative;
				width: 40px;
				
				@include break(small) {
					margin: 0 $gap / 2 0 0;					
				}
				
				@include break(medium) {
					height: 42.5px;
					width: 42.5px;					
				}
				
				@include break(large) {
					height: 50px;
					width: 50px;					
				}
				
				&:hover {
					background-color: $secondary-light;
					transform: translateY(-2px);
					box-shadow: 0px $line*3 0px 0px rgba($primary,0.1);
				}
				&.twitter {
					.icon {
						height: 2rem;
						width: 2rem;
					}
				}
				&.whatsapp {
					.icon {
						// height: 1.5rem;
						// width: 1.5rem;
					}
				}
				&.facebook {
					.icon {
						width: 2rem;
						height: 2rem;
					}
				}
				.icon {
					color: $primary;
					// font-size: 2rem;
					width: 3rem;
					width: 3rem;
				}
			}
		}
	}  
}