/* ++++++++++++++++++++
+++ LAYOUT: ARTICLE +++
++++++++++++++++++++ */

// Article represents the part of a page that includes posted content including title and summary. Displaying as an inline-block allows the article to center nicely with or without a sidebar present.

.article {
    margin-top: 0;
    padding: 0 $gap;
    position: relative;
    width: 100%;
    
    @include break(small) {
	    margin-left: 0;
	    padding: $gap * 2 $gap;    
    }

    &-media {
	    overflow: hidden;
	    position: relative;
	    
	    @include break(medium) {
		    height: 60vh;
	    }
	    
	    img {
            width: 100%;
            
            @include break(medium) {
	            height: 100%;
	            left: 0;
	            object-fit: cover;
	            position: absolute;
	            top: 0;
            }
	    }
    }

    &-header {
        &-summary {
            p {
                font: $light 1.33em/1.5 $font-header;
            }
        }
    }

    &-footer {
        border-top: $line solid $grey80;
        margin-top: $gap * 2;
        padding-top: $gap;
    }

    @include break(small) {
        padding: 0;
    }

    @include break(medium) {
	    @include flexbox(66.66%);
        float: left;
    }
    
    
    //Inline components
    
    .accordion-outer, .pullquote {
		width: 100%;
    }
    .accordion-outer {
	    background-color: $grey90;
	    margin: $gap * 2 0;
    }
    .pullquote {
	    &-image {
			+ .pullquote-info {	
				blockquote {
					font-size: 1.3em;
				}
			}
	    }
	    cite {
		    font-size: 1em;
	    }
    }
	.download {
	    padding: $gap * 2 0;
	    
		&-block {
			@include flexbox(100%);
			
			@include break(small) {
				@include flexbox(50%);					
			}
		}
	}
	.cta {
		background-color: $grey90;
		border-radius: $corner;
		padding: $gap * 3;
		
		&-inner {
			text-align: left;	
		}
	}
}
