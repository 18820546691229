/* ++++++++++++++++++
+++ LAYOUT: CARDS +++
++++++++++++++++++ */

// Cards are more visually interesting and content-light than a standard listing page.

.cards {
    @extend %flex;
    position: relative;
	justify-content: center;
    width: 100%;
	
	@include break(tablet) {
		width: 104%;
		margin-left: -$gap;
	}
	
	@include break(medium) {
		width: 103%;
		margin-left: -$gap;
	}
	
	@include break(large) {
		width: 102.5%;
		margin-left: -$gap;
	}

    > * {
        @extend %flex;
        @include flexbox(100%);
        margin: $gap 0;
		
		@include break(medium) {
			margin: $gap * 1.5 0;			
		}
    }

    @include break(tablet) {
        > * {
	        @include flexbox(50%);
        }
    }

    @include break(medium) {
        > * {
	        @include flexbox(33.33%);
        }
    }
	
    &-outer {
	    .section-header {
		    &.container {
			    @include break(medium) {
			    	padding: 0 $gap * 2;
			    }			    
		    }
	    }
	    .container {
		    @include break(large) {
		    	padding: 0;
		    }
	    }
    }
}

.card {
    @extend %card;
	@extend %transition;
    @extend %flex;
	border: 1px solid $grey80;
    overflow: hidden;
    width: 100%;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);

    @include break(tablet) {
	    margin: 0 $gap;
    }
	
	&.hovered,
	&:hover {
		// border-color: $secondary;
		box-shadow: $shadow;
		transform: translateY(-5px);
		
		.card-header-title {
			text-decoration: underline;
		}
	}

    &-image {
        border-radius: $corner $corner 0 0;
        display: block;
        margin: 0;
        position: relative;
        width: 100%;
		
		.no-images & {
			display: none;
		}
        
        @include break(tablet) {
        	height: 25vh;
        }	

	    img {
			@extend %object-fit;
	    }
    }

    &-wrap {
	    align-self: stretch;
	    height: 100%;
        margin: 0;
        
        &-inner {
	        padding: $gap * 1.5;
			
			.card-label {
				@extend %font-meta;
				
				&:not(:last-of-type) {
					margin-right: $gap / 3;
					
					&:after {
						content: '•';
						margin-left: $gap / 3;
					}
				}
				
				& + .card-header {
					border-top: 1px solid $grey80;
					margin-top: $gap / 2;
					padding-top: $gap;
				}
			}
			
			.metalabel {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding-bottom: $gap * 1.15;
				border-bottom: 1px solid $grey80;
				margin-bottom: $gap * 1.5;
				
				&:before {
					content: ' ';
					display: inline-block;
					width: 16px;
					height: 16px;
					border-radius: $corner;
					background-color: $lime;
					margin-right: $gap / 2;
				}
				
				a {
					margin-left: 0;
				}
			}
			
			.card-icon {
				margin-left: 0;
				margin-bottom: $gap;
			}
        }
    }

    &-header {
        margin-top: 0;
        
        + .card-summary {
			color: $primary-light;
			font-size: 1.15rem;
	        margin-top: $gap;
        }

        &-title {
	        font-size: 1.6em;
			font-weight: $bold;
	        
            a {
				@extend %text-hover;
				&:hover {
					text-decoration: underline;
				}
            }
            
        }

        &-date {
            @extend %font-meta;
            display: block;
            margin-top: $gap / 2;
        }
    }
    &-button {
	    &-outer {
		    margin-top: $gap;
	    }
    }
}

// Report layout

.reports {
	.card {
		&-image {
			background-color: $grey90;
			overflow: hidden;
			
			img {
				@extend %transition;
				bottom: -1em;
				border-radius: $corner $corner 0 0;
				// box-shadow: $shadow;
				margin: $gap * 3 auto 0;
				position: relative;
				width: 60%;
				
				&:hover {
					bottom: -0.5em;
				}
			}
		}
		&-wrap {
			// box-shadow: 0px -8px 22px 0px rgba($black, 0.3);
			z-index: 2;
		}
	}	
}

// Listing layout

.listing {
	.card {
		background-color: transparent;
		border-radius: 0;
		box-shadow: none;

		&-wrap {
			border-top: solid 5px $primary;
			
			&-inner {
				padding: $gap 0 0 0;
			}
		}
	}	
}


// Two column layout
.two-col {
	.cards {
		> * {
	        @include break(tablet) {
		        @include flexbox(50%);	        
	        }
	        
	        .card {
	            @include break(medium) {
		            margin: 0 $gap * 2;
	            }
	            
	            &-image {
		            height: 35vh;
	            }
	        }
		}
		
		article {
			// margin: $gap 0;
			
			@include break(medium) {
				// margin: 0 $gap * 1.5;
				
				&:nth-of-type(odd) {
					.card {
						// margin-left: 0;
						margin-left: $gap;
					}
				}
				
				&:nth-of-type(even) {
					.card {
						// margin-right: 0;
						margin-right: $gap;
					}
				}
			}
		}
	}
}

// Three column layout
.three-col {
	.cards {
		> * {
			@include break(medium) {
				@include flexbox(33.33%);			
			}
	        .card {
				@include break(medium) {
		            margin: 0 $gap;
				}
	            
	            &-image {
		            height: 25vh;
	            }
	        }
			
		    &:first-child:nth-last-child(2), &:first-child:nth-last-child(2) ~ article {
				@include break(tablet) {
					@include flexbox(50%);
				}
	            
	            .card {
		            &-image {
			            height: 35vh;
		            }
	            }
		    }			
		}
	}
}

// Four column layout
.four-col {
	.cards {
		> * {
		    @include break(large) {
			    @include flexbox(25%);
		    }
		    .card {
				@include break(medium) {
		        margin: 0 $gap * 1.5;
				
				&-image {
						height: 17.5vh;
					}
				}
		    }
		    
		    &:first-child:nth-last-child(3), &:first-child:nth-last-child(3) ~ article {
				@include break(tablet) {
					@include flexbox(33.33%);
				}
		    }
		    
		    &:first-child:nth-last-child(2), &:first-child:nth-last-child(2) ~ article {
				@include break(tablet) {
					@include flexbox(50%);
				}
	            
	            .card {
		            margin: 0 $gap * 1.5;
		            
		            &-image {
			            @include break(large) {
				            height: 35vh;
			            }
		            }
	            }
		    }
		}	
	}
}